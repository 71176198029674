import React, {useState} from 'react';
import styled from 'styled-components';
import {useAnimationFrame} from '@hello10/react-hooks';

const TrafficSchoolStyled = styled.div`
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const images = [
  '3-point-turn.jpg',
  'distracted-parked.jpg',
  'no-passing-zone.jpg',
  's7fatalities_web.jpg',
  '3-second-rule.jpg',
  'distracting-passenger.jpg',
  'oil-change.jpg',
  'school-bus.jpg',
  '35mph.jpg',
  'do-not-enter.jpg',
  'parked-cars.jpg',
  'school.jpg',
  'I-5.jpg',
  'doctor.jpg',
  'penny-test.jpg',
  'seatbelt-1.jpg',
  'accident.jpg',
  'driver-error.jpg',
  'radio.jpg',
  'seatbelt-2.jpg',
  'angry-man.jpg',
  'electirc-car.jpg',
  'relax.jpg',
  'seats.jpg',
  'angry-senior.jpg',
  'enter-intersection.jpg',
  'right-of-way.jpg',
  'senior-drive.jpg',
  'animal-road.jpg',
  'family.jpg',
  'right-turn.jpg',
  'share-the-road.jpg',
  'baby-car-seat.jpg',
  'fire-truck.jpg',
  'road-rage-2.jpg',
  'sharrows.jpg',
  'back-up-cam.jpg',
  'flat-tire.jpg',
  'road-rage-gun.jpg',
  'side-mirror.jpg',
  'basic-speed-law.jpg',
  'fog.jpg',
  'road-rage.jpg',
  'side-mirror2.jpg',
  'bike-lane-1.jpg',
  'freeway-2.jpg',
  'roadside.jpg',
  'skids.jpg',
  'bike-lane-2.jpg',
  'freeway-drift.jpg',
  'rr-crossing.jpg',
  'smoking.jpg',
  'bike-lane.jpg',
  'friendly-driver.jpg',
  'rural-driving-2.jpg',
  'snow-1.jpg',
  'brake-light.jpg',
  'frustrated-driver.jpg',
  'rural-driving.jpg',
  'snow-2.jpg',
  'brake-repair.jpg',
  'green-hybrid.jpg',
  'rural-road.jpg',
  'speed-bump.jpg',
  'brakes-car.jpg',
  'headlight.jpg',
  's1impairment_web.jpg',
  'speeding.jpg',
  'break-down.jpg',
  'headlights.jpg',
  's1sleep_web.jpg',
  'splitting-lane.jpg',
  'cali-license-plate.jpg',
  'hov.jpg',
  's1stress_web.jpg',
  'stop-sign.jpg',
  'car-horn.jpg',
  'hydroplane.jpg',
  's2lawsgavel._web.jpg',
  'stress-relief.jpg',
  'car-roadside.jpg',
  'icy-road.jpg',
  's2pedestrian_signal_web.jpg',
  'stressed.jpg',
  'car-seat.jpg',
  'intersection.jpg',
  's2pedestrians_web.jpg',
  'tesla-nav.jpg',
  'change-tire.jpg',
  'intersection2.jpg',
  's3airbag_web.jpg',
  'tire-tread.jpg',
  'child-around-cars.jpg',
  'lane-change-diagram.jpg',
  's3brakefailure_web.jpg',
  'truck-blind-spot.jpg',
  'children-play.jpg',
  'large-truck.jpg',
  's3brakelight_web.jpg',
  'truck-blind-spot2.jpg',
  'city-driving.jpg',
  'left-green.jpg',
  's3brakes_web.jpg',
  'turn-sign.jpg',
  'classic-car.jpg',
  'left-turn.jpg',
  's3daylightuseoflights_web.jpg',
  'u-turn.jpg',
  'collision.jpg',
  'long-line.jpg',
  's3stalled_engine_web.jpg',
  'visual-lead-time.jpg',
  'construction.jpg',
  'looking-down-road.jpg',
  's4NOTS_web.jpg',
  'wide-turning-truck.jpg',
  'convex-mirror.jpg',
  'loose-gravel.jpg',
  's4computer_letter_web.jpg',
  'winding-road.jpg',
  'cop-intersection.jpg',
  'max-speed-law.jpg',
  's4driverslicense_web.jpg',
  'windshield.jpg',
  'mechanic.jpg',
  's4other_insurance_web.jpg',
  'wipers-1.jpg',
  'dips.jpg',
  'motorcycle-freeway.jpg',
  's5suv_web.jpg',
  'wipers-2.jpg',
  'distracted-driver-1.jpg',
  'nav-1.jpg',
  's5visualleadtime_web.jpg',
  'woman-car-accident.jpg',
  'distracted-driver-2.jpg',
  'newcalifornia_web.jpg',
  's6brightsunlight_web.jpg',
  'wrong-way.jpg',
  'distracted-driver-3.jpg',
  'newmeeting.jpg',
  's6lowlight_web.jpg',
  'yield.jpg',
  'distracted-driver-4.jpg',
  'newpedestrian.jpg',
  's6rain2_web.jpg',
  'distracted-driver-5.jpg',
  'newrollover.jpg',
  's7desginatedriver_web.jpg',
  'distracted-earbuds.jpg',
  'night-lighting.jpg',
  's7drinkanddrivingintro_web.jpg'
];


let start;

export default function TrafficSchoolPage () {
  const [index, setIndex] = useState(0);

  useAnimationFrame(({total})=> {
    const step_time = 600;
    setIndex((index)=> Math.floor(total / step_time) % images.length);
  });

  const name = images[index];
  const src = `/img/trafficschool/${name}`;
  const background = `url(${src}) no-repeat center center fixed`;
  return (
    <TrafficSchoolStyled style={{background}} />
  );
}
