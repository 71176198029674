import
	React,
	{ Component }
from 'react';

import {
	randElement,
	rgb
} from './Utils';

// http://en.wikipedia.org/wiki/Grayscale#Converting_color_to_grayscale
const GRAY_MODES = {
  'CIE 1931': [.2126, .7152, .0722],
  'rec601 luma': [.299, .587, .114]
};

const CHAR_SIZE = 12;
const ASCII_RANGE = [33, 126];
const AUTORENDER_INTERVAL = 200;
const AUTORENDER_START_DELAY = 1000;

export default class AsciiArtComponent extends Component {
	static defaultProps = {
		mode_name: 'CIE 1931',
		font: 'Courier',
		char_count: 8,
		colors: [[0, 0, 0], [0, 0, 255]]
	};

	state = {
		autorender: null,
		loading: true
	};

	constructor (props) {
		super(props);
		this.setupGroupedChars();
	}

	componentWillMount () {
		let chars = this.chooseChars();
		this.setState({chars});
		let {src} = this.props;
		this.loadImage(src);
	}

	componentWillReceiveProps ({src}) {
		if (src !== this.props.src) {
			this.loadImage(src);
		}
	}

	componentDidMount () {
		setTimeout(this.toggleAutoRender, AUTORENDER_START_DELAY);
	}

	// getGroupedChars
  // ----------------
  // This function renders each character in this font and sorts by the
  // how many pixels they occupy so they can be used for ascii rendering
  //
  // TODO : allow for randomization and pulling evenly from pixel counts

  setupGroupedChars () {
		let {
			font,
			char_count
		} = this.props;

    let canvas_size = 50;
    let char_size = 16;

    let canvas = document.createElement('canvas');
    canvas.width = canvas_size;
    canvas.height = canvas_size;

    let context = canvas.getContext('2d');
    context.fillStyle = 'black';
    context.font = `${char_size}px ${font}`;

    let possible_chars = [];

    let min_total = Infinity;
    let max_total = 0;

		let [low, high] = ASCII_RANGE;
    for (let i = low; i <= high; i++) {
			let char = String.fromCharCode(i).toString();

      context.fillText(char, 20, 20);
      let img_data = context.getImageData(0, 0, canvas_size, canvas_size);

      let total = img_data.data.reduce((total, value)=> {
				return (total + value);
			}, 0);

    	if (total > 0) {
        min_total = Math.min(min_total, total);
        max_total = Math.max(max_total, total);
        possible_chars.push({char, total});
			}

      context.clearRect(0, 0, canvas_size, canvas_size);
		}

		this.group_size = Math.ceil(255 / char_count);
		this.grouped_chars = [];

		let range = max_total - min_total;
		let range_group_size = Math.ceil(range / char_count);

    possible_chars.forEach((char)=> {
      let total = char.total - min_total;
      let index = Math.floor(total / range_group_size);
      if (!this.grouped_chars[index]) {
        this.grouped_chars[index] = [];
			}
      this.grouped_chars[index].push(char.char);
		});
	}

	chooseChars () {
		let chars = this.grouped_chars.map(randElement);
		chars.unshift('&nbsp');
		return chars;
	}

	render () {
		let {
			colors,
			font
		} = this.props;

		let {loading} = this.state;

		if (loading) {
			return '';
		}

		let [bg_color, color] = colors.map(rgb);

		return (
			<div
				onClick={this.toggleAutoRender}
				style={{
					backgroundColor: bg_color,
					color,
					fontFamily: font,
					fontSize: `${CHAR_SIZE}px`
				}}
				dangerouslySetInnerHTML={{__html: this.renderImage()}}
			/>
		);
	}

	renderImage () {
		let {img} = this.state;

    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

		let context = canvas.getContext('2d');
    context.drawImage(img, 0, 0);
    let img_data = context.getImageData(0, 0, img.width, img.height);
    let img_data_h = img_data.height;
    let img_data_w = img_data.width;
    img_data = img_data.data;

    let html = '';

		let h_inc = CHAR_SIZE * 2;
		let w_inc = CHAR_SIZE;
    for (let h = 0; h < img_data_h; h += h_inc) {
      let text = '';
      for (let w = 0; w < img_data_w; w += w_inc) {
        let i = (w + (img_data_w * h)) * 4;
        let red = img_data[i];
        let green = img_data[i+1];
        let blue = img_data[i+2];

        let rgb = [red, green, blue];
        let gray = this.rgbToGray(rgb);
        let char = this.grayToChar(gray);
        text += char;

	      // text = escapeHtml(
	      //   text : text
	      //   skip : ['&']
	      // )
			}

      html += `<div>${text}</div>`;
		}

		return html;
	}

	getMode () {
		let {mode_name} = this.props;
		return GRAY_MODES[mode_name];
	}

  loadImage (src) {
		let img = new Image();
  	img.src = src;

		this.setState({
			img,
			loading: true
		});

    img.onload = ()=> {
      this.setState({
				loading: false
			});
		};
	}

  rgbToGray (rgb) {
    let mode = this.getMode();

    let dot = 0;
		for (let i = 0; i < mode.length; i++) {
			dot = dot + parseInt(mode[i] * rgb[i], 10);
		}
		return dot;
	}

  grayToChar (gray) {
		let {chars} = this.state;
    let index = Math.floor(gray / this.group_size)
    return chars[index];
	}

  rerender = ()=> {
    let chars = this.chooseChars();
		this.setState({chars});
	}

  toggleAutoRender = ()=> {
		let {autorender} = this.state;
    if (autorender) {
      clearInterval(autorender);
		}
    else {
      setInterval(this.rerender, AUTORENDER_INTERVAL)
		}
	}
}
