import React from 'react';
import styled from 'styled-components';

const FortyEightLightYearsStyled = styled.div`
  margin: 5px;

  table {
    tr, th, td {
      color: #333;
      text-align: center;
      padding: 5px;
    }

    td:first-child {
      text-align: right;
      font-weight: bold;
    }
  }
`;

export default function FortyEightLightYears () {
  return (
    <FortyEightLightYearsStyled className="FortyEightLightYearsPage">
      <table>
        <thead>
          <tr>
            <th>
              role
            </th>
            <th>
              Universe A
            </th>
            <th>
              Universe B
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              randall adams
            </td>
            <td>
              patrick swayze
            </td>
            <td>
              kurt russell
            </td>
          </tr>

          <tr>
            <td>
              andre washington
            </td>
            <td>
              boris kodjoe
            </td>
            <td>
              james blake
            </td>
          </tr>

          <tr>
            <td>
              david adams (son)
            </td>
            <td>
              tobey mcquire
            </td>
            <td>
              jake gylenhaal
            </td>
          </tr>

          <tr>
            <td>
              william washington (son)
            </td>
            <td>
              derek luke
            </td>
            <td>
              sean patrick thomas
            </td>
          </tr>

          <tr>
            <td>
              police chief
            </td>
            <td>
              gary busey
            </td>
            <td>
              nick nolte
            </td>
          </tr>

          <tr>
            <td>
              internal affairs
            </td>
            <td>
              jane curtin
            </td>
            <td>
              judy dench
            </td>
          </tr>

          <tr>
            <td>
              jenny (william's girlfriend)
            </td>
            <td>
              leslie bibb
            </td>
            <td>
              maggie grace
            </td>
          </tr>

          <tr>
            <td colSpan="3">
              ...
            </td>
          </tr>
        </tbody>
      </table>
    </FortyEightLightYearsStyled>
  );
}
