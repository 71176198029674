import React, {useState} from 'react';
import styled from 'styled-components';

const StoriesPageStyled = styled.div`
  margin: 20px;

  .Story {
    max-width: 444px;

    div {
      letter-spacing: 0.25px;
      text-align: justify;
      font-size: 16px;
      line-height: 30px;
      margin-top: 30px;
    }
  }
  .Links {
    cursor: pointer;
    font-size: 12px;
    .Current {
      text-decoration: underline;
    }
  }
`;

const Stories = {
  'That night when we were walking the dogs': (
    <div className="Story">
      <div>
        I laugh whenever I think of that night when we were walking the dogs by the old house. The poodle man night.
      </div>

      <div>
        I think we'd mostly been taking them out late at night to avoid people, but I guess we figured we’d take a walk while there was still some light out for a change. Left the house and took a left down that alley we never learned the name of and started joking about the people in one of the apartments above blasting Zeppelin IV at ludicrous speed... the ones with the kitchens that hung out over their garages into the alley just daring an earthquake or drunk in a moving truck to do their absolute best.
      </div>

      <div>
        I think I remember more about the garages than the people who lived above them, except maybe that one couple we watched moving in get into an argument over where to put the couch. We were sitting on the deck a couple days later and they were eating dinner and laughing at something on the laptop propped on a cushion between them. We got stoned and decided it was those kitchens hovering out into the alley that made the residents constantly reorganize their garages full of dusty aspirational beach props.
      </div>

      <div>
        So we're walking and laughing like idiots trying to imagine who or what was in that apartment rocking out to Misty fucking Mountain Hop so hard that we could still hear it nearly two blocks away. You're about midway through some sort of channel the spiiiiirits while shitting your pants Zeppelin super fan interpretive dance when we notice this man walking towards us with a near perfectly groomed poodle wandering around him. The dog is off leash and the guy's in sandles and balding but still holding onto the ponytail dream. He's intently trying to decipher just what it is you're doing when the dogs notice the show poodle digging its face into some tall grass on the corner.
      </div>

      <div>
        They both start barking like idiots and we tug them past the poodle, which clearly doesn't care about anything other than the epic smell quest it is most deeply engaged in. We pass ponytail guy and I manage something like "Sorry about that, they haven't seen other dogs in awhile" and then regret it as soon as I see his eyes light up at the opportunity to drop some of his choice poodle facts. "You know, I've actually found that obedience school can real---"
      </div>

      <div>
        "Please <i>do not</i>", you break in, in some budget impression of Paris Hilton trying on a Scottish accent, "fucking confuse this man's dull canadian manners for him <i>or me</i> giving even <i>two</i> fucks about you or your <i>stupid fucking zen poodle</i>."
      </div>

      <div>
        The man's face has gone from deeply confused to desperately buoyant and now some unsteady mix of confusion, devastation, and pity. You bust up and I almost fall over and the dogs are somehow laughing too. We run down the alley until we get to that basketball court they've taken the rim off of. We sit on the green pavement until its almost dark out and stare up at people having a party in a big house on the hill. We can't hear Zeppelin IV from the apartment anymore, just some dance music people at the house are falling around and drinking to.
      </div>

      <div>
        I'm pretty sure we walked by the couple sunbathing at the beach a couple weeks before we moved out. All I can remember beyond that is they generally seemed to get along pretty well after they figured out the couch belonged along the far wall.
      </div>
    </div>
  )
};

function Links ({title, setTitle}) {
  const titles = Object.keys(Stories);
  const $links = titles.map((t)=> {
    // TODO: generalize this add className to base conditionally and then
    //       join to str pattern to function/helper
    const classNames = ['Story'];
    if (t === title) {
      classNames.push('Current');
    }
    return (
      <span
        key={t}
        className={classNames.join(' ')}
        onClick={()=> setTitle(t)}
      >
        {t}
      </span>
    );
  });

  return (
    <div className="Links">
      {$links}
    </div>
  );
}

export default function StoriesPage () {
  const titles = Object.keys(Stories);
  const [title, setTitle] = useState(titles[0]);
  const story = Stories[title];

  return (
    <StoriesPageStyled>
      <Links
        title={title}
        setTitle={setTitle}
      />
      {story}
    </StoriesPageStyled>
  );
}
