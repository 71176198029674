import React from 'react';
import Styled from 'styled-components';

import {useTheme, ts} from './useTheme';

const ButtonStyled = Styled.button`
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
  
  &:focus {
    outline: 0;
  }
  &:active {
    transform: translateX(1px) translateY(-1px);
  }
`;

const OutlineButtonStyled = Styled(ButtonStyled)`
  border-color: ${ts('foreground100')};
  background-color: ${ts('background100')};
  color: ${ts('foreground100')};
  &:hover {
    background-color: ${ts('foreground15')};
  }
  &:active {
    background-color: ${ts('foreground65')};
  }
`;

const SolidButtonStyled = Styled(ButtonStyled)`
  border-color: ${ts('foreground100')};
  background-color: ${ts('foreground100')};
  color: ${ts('background100')};
  &:hover {
    border-color: ${ts('foreground85')};
    background-color: ${ts('foreground85')};
  }
  &:active {
    border-color: ${ts('foreground35')};
    background-color: ${ts('foreground35')};
  }
`;

export function OutlineButton (props) {
  const theme = useTheme();
  return (
    <OutlineButtonStyled
      className="Button OutlineButton"
      theme={theme}
      {...props}
    />
  );
}

export function SolidButton (props) {
  const theme = useTheme();
  return (
    <SolidButtonStyled
      className="Button OutlineButton"
      theme={theme}
      {...props}
    />
  );
}
