import React from 'react';

import * as Operators from './Operators';
import Z16Square from './Z16Square';
import Z16SquareCanvas from './Z16SquareCanvas';

export default  function Z16OperatorsLegend () {
  const ops = Object.keys(Operators).map((label)=> {
    const op = Operators[label];
    const square = Z16Square.fromOp(op);
    console.log(label, op, square);
    return (
      <div className="Z16Operator">
        <Z16SquareCanvas
          square={square}
          scale={16}
        />
        <div className="OperatorLabel">
          {label}
        </div>
      </div>
    )
  });

  return (
    <div className="Z16OperatorsLegend">
      {ops}
    </div>
  );
}
