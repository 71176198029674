import
	React,
	{ Component }
from 'react';

export default class YouTubeSamplerComponent extends Component {
	render () {
		return (
			<div className="YouTubeSampler">
				<div id="Overlay"></div>
				<div id="Player"></div>
			</div>
		);
	}

	addCallback () {
		let {
			id
		} = this.props;

		window.onYouTubeIframeAPIReady = ()=> {
			let player = new window.YT.Player('Player', {
				height: '390',
				width: '640',
				videoId: id,
				playerVars: {
					autoplay: 1,
					controls: 0,
					disablekb: 1,
					enabledjsapi: 1,
					playlist: id,
					loop: 1,
					modestbranding: 1,
					showinfo: 0,
					iv_load_policy: 3,
					start: 1,
					rel: 0
				}
			});
			this.setState({player});
		}
	}

	addKeyboardHandler () {
		document.body.addEventListener('keypress', (event)=> {
			let {key} = event;
			let {player} = this.state;
			let {keymap} = this.props;
			if (key in keymap) {
				event.preventDefault();
				let seconds = keymap[key];
				player.seekTo(seconds, true);
				return false;
			}
		});
	}

	// TODO: make sure this only happens if script doesn't exist on page..?
	addYoutubeApi () {
		const tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";
		const first_script_tag = document.getElementsByTagName('script')[0];
		first_script_tag.parentNode.insertBefore(tag, first_script_tag);
	}

	componentDidMount () {
		console.log('hello?');
		this.addYoutubeApi();
		this.addCallback();
		this.addKeyboardHandler();
	}
}
