import {useSingleton} from '@hello10/react-hooks';
import Color from '@hello10/color';

const black = Color.fromName('Black');
const white = Color.fromName('White');

const percents = [];
for (let percent = 100; percent >= 5; percent -= 5) {
  percents.push(percent);
}

class Theme extends useSingleton.Singleton {
  initialize (state) {
    return {
      dark: false,
      color: black,
      ...state
    };
  }

  toggleMode = ()=> {
    const {dark} = this.state;
    this.setState({dark: !dark});
  };

  get dark () {
    return this.state.dark;
  }

  setColor = (color)=> {
    this.color = color;
  };

  set color (color) {
    this.setState({color});
  }

  get color () {
    return this.state.color;
  }

  background (alpha) {
    const background = this.dark ? this.color : white;
    return background.css({format: 'rgba', alpha});
  }

  foreground (alpha) {
    const color = this.dark ? white : this.color;
    const css = color.css({format: 'hex', alpha});
    return css;
  }

  primary (alpha) {
    return this.color.css({format: 'hex', alpha});
  }

  get percents () {
    return percents;
  }
}

for (const percent of percents) {
  const alpha = (percent / 100.0);
  for (const type of ['background', 'foreground', 'primary']) {
    const name = `${type}${percent}`;
    Object.defineProperty(Theme.prototype, name, {
      configurable: false,
      enumerable: false,
      get () {
        return this[type](alpha);
      }
    });
  }
}

export function useTheme (options) {
  return useSingleton(Theme, options);
}

export function ts (prop) {
  return ({theme})=> {
    return theme[prop];
  };
}

export default useTheme;
