import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import classie from '@hello10/classie';

import {useTheme, ts} from './useTheme';

const SelectStyled = Styled.div`
  font-size: 12px;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  border-color: ${ts('foreground100')};
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 100%;
  background-color: ${ts('foreground100')};
  color: ${ts('background100')};

  .SelectBody {
    position: relative;
    display: flex;
    flex-direction: column;

    .SelectSelected {
      padding: 8px;
      font-weight: bold;
    }

    .SelectOptions {
      background-color: white;
      border-top: 1px solid ${ts('background100')};
      display: none;
      overflow-y: scroll;
      max-height: 400px;
      &.Open {
        display: block;
      }
    }
  }
`;

export default function Select ({options, isSelected, onSelect, Option, Selected}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  if (!Option) {
    Option = ({value})=> value;
  }

  if (!Selected) {
    Selected = ({value})=> value;
  }

  function toggleOpen () {
    console.log('opening!');
    setOpen(!open);
  }

  function onClick (value) {
    return function click () {
      setOpen(false);
      onSelect(value);
    };
  }

  let selected;
  const $options = options.map((val, i)=> {
    if (isSelected(val)) {
      selected = val;
    }
    const classes = classie({
      SelectOption: true,
      Selected: isSelected(val)
    });
    return (
      <div
        key={i}
        className={`${classes}`}
        onClick={onClick(val)}
      >
        <Option value={val}/>
      </div>
    );
  });

  return (
    <SelectStyled
      className="Select"
      onClick={toggleOpen}
      theme={theme}
    >
      <div className="SelectBody">
        <div
          className="SelectSelected"
        >
          <Selected value={selected} />
        </div>
        <div
          className={`${classie({
            SelectOptions: true,
            Open: open
          })}`}
        >
          {$options}
        </div>
      </div>
    </SelectStyled>
  );
}

Select.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  isSelected: PropTypes.func,
  Selected: PropTypes.func,
  Option: PropTypes.func
};
