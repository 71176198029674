import React from 'react';

import {Page} from '../Core/';
import './HowToStopAWedding.scss';

const NUM_IMAGES = 16;

export default class HowToStopAWeddingPage extends Page {
	title () {
		return 'How To Stop A Wedding';
	}

	meta () {
		return {
			description: 'You too can stop a wedding'
		};
	}

	pageClass () {
		return 'HowToStopAWeddingPage';
	}

	state = {
		step: 1
	}

	componentDidMount () {
		window.setInterval(this.update, 200)
	}

  renderBody () {
		let {step} = this.state;
		if (step.length < 2) {
			step = `0${step}`;
		}

		return (
			<a
				href="http://www.wikihow.com/Stop-a-Wedding"
				className="center"
			>
				<div className="container">
					<div className="step">
						{step}
					</div>
					<img
						src={this.getImage()}
						alt={`step ${step}`}
					/>
				</div>
			</a>
		);
	}

	getImage () {
		let {step} = this.state;
		return `/img/howtostopawedding/670px-Stop-a-Wedding-Step-${step}.jpg`
	}

	update = ()=> {
		let {step} = this.state;
		step++;
		if (step > NUM_IMAGES) {
			step = 1;
		}
		this.setState({step});
	}
}
