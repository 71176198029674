import React from 'react';

import {Page} from '../Core/';
import './Playlists.scss';

const PLAYLISTS = [
	{
		uri: 'spotify:user:stephen.handley:playlist:0Tl6bN6WtdnE74zztzM6vh'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:2Ys56FIwDmAN6AExMBH5Ew'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:3CgdIVGbbcuZ4zlkEJ7OO2'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:1nFfmV6KgI62mOwKjv78Ww'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:5oCZMl8GTTw4dw1eHMAoBl'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:4kFtZzejnAPaeVUYDa0cyG'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:6glZZVMQ6Ve1N5xuYJNRt3'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:6KyP6RCVKi0uia2KWKVppf'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:6ANFfSmmA6OOt91Hmx4CRU'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:08oYx54qgORXElM5JgueTF'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:4NDDhifhIAEEmwYs3dBLgl'
	},
	{
		uri: 'spotify:user:stephen.handley:playlist:1wKraMbdojVA6Qan51fv7n'
	}
];

export default class PlaylistsPage extends Page {
	renderPlaylist ({uri}) {
		let path = uri.replace('spotify:', '').replace(/:/g, '/');
		let src = `https://open.spotify.com/embed/${path}`;

		return (
			<div
				className="Playlist"
				key={uri}
			>
				<iframe
					title={uri}
					src={src}
					width="300"
					height="300"
					frameBorder="0"
					allowtransparency="true"
				></iframe>
			</div>
		);
	}

	renderBody () {
		return (
			<div className="Playlists">
				#{PLAYLISTS.map(this.renderPlaylist)}
			</div>
		);
	}

	pageClass () {
		return 'PlaylistsPage';
	}
}
