import React from 'react';

import {Page} from '../Core/';
import TelegenicLoader from './TelegenicLoader';
import './Telegenics.scss';

// lol http://telegenics.net/telegenics.html

export default class TelegenicsPage extends Page {
	urlTitle () {
		let {match: {params: {title}}} = this.props;
		return title;
	}

	title () {
		return `Telegenic ${this.urlTitle()}`;
	}

	renderBody () {
		return (
			<TelegenicLoader />
		);
	}

	pageClass () {
		return 'TelegenicPage';
	}
}
