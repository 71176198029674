import React from 'react';

import {Page} from '../Core/';
import './Shirts.scss';

function Shirt ({name}) {
  const url = `https://teespring.com/${name}`;
  const src = `/img/shirts/${name}.jpg`;
  return (
    <a className="Shirt" href={url}>
      <img src={src}/>
    </a>
  );
}

export default class ShirtsPage extends Page {
  renderBody() {
    const names = [
      'chocolate-aack',
      'hwga-skydiver',
      'hwga-albuquerque',
      'shirtshirtshirtshirtshirtshirt'
    ];

    const $shirts = names.map((name)=>
      <Shirt
        key={name}
        name={name}
      />
    );

    return (
			<div className="Shirts">
				{$shirts}
			</div>
    );
  }

  pageClass () {
    return 'ShirtsPage';
  }
}
