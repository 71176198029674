import _ from 'lodash';

export function randInt (max) {
  return Math.floor(Math.random() * max);
}

export function randElement (array) {
  let index = randInt(array.length);
  return array[index];
}

export function randIntRange (args) {
  let {min, max} = args;
  let width = max - min
  return function () {
    return (randInt(width) + min);
  };
}

export function mapRanges (args) {
  let {input, output, value} = args;

  let [output_min, output_max] = output;
  let [input_min , input_max] = input;

  let output_range = (output_max - output_min);
  let input_range  = (input_max  - input_min);
  let input_offset = (value - input_min);
  let scale = (output_range / input_range);

  return ((input_offset * scale) + output_min);
}

export function randomColor () {
  return _.range(3).map(()=> {
    return randInt(255);
  });
}

export function complementColor (color) {
  return color.map((c)=> {
    return (255 - c);
  });
}

export function randomColorAndComplement () {
  let color = randomColor();
  return [color, complementColor(color)];
}

export function escapeHtml ({text, skip = []}) {
  let entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
  };

  return String(text).replace(/[&<>"'/]/g, (s)=> {
    let should_skip = (skip.indexOf(s) !== -1);
    return should_skip ? s : entityMap[s];
  });
}

export function rgb (values) {
  let [r, g, b] = values;
  return `rgb(${r},${g},${b})`;
}

export function euclideanDistance (p1, p2) {
  if (p1.length !== p2.length) {
    throw new Error("Points must be of same dimension");
  }

  let sum = 0;
  for (let i = 0; i < p1.length; i++) {
    let diff = p2[i] - p1[i];
    sum += Math.pow(diff, 2);
  }

  return Math.sqrt(sum);
}
