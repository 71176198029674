import React from 'react';

import {Page} from '../Core/';
import './NotFound.scss';

export default class NotFoundPage extends Page {
  renderBody () {
    return (
			<div className="not_found_message">
				Page not found k sorry
			</div>
    );
  }

  pageClass () {
    return 'NotFoundPage';
  }
}
