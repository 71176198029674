import React from 'react';
import {Page} from '../Core/';
import Eurorack from './EurorackSizes';
import './Modular.scss';

class ModularPage extends Page {
	componentDidMount () {
		let ac = this.ac = new AudioContext();
		let osc = new OscillatorNode(ac, {frequency: 80});
		let gain = new GainNode(ac, {gain: 0.5});
		let lfo = new OscillatorNode(ac, {frequency: 5});
		let lfo_scale = new GainNode(ac, {gain: 0.2});

		let lfo_freq_mod = new OscillatorNode(ac, {frequency: 0.1});
		let lfo_freq_mod_scale = new GainNode(ac, {gain: 4});
		lfo_freq_mod.connect(lfo_freq_mod_scale).connect(lfo.frequency);

		osc.connect(gain);
		lfo.connect(lfo_scale).connect(gain.gain);

		gain.connect(ac.destination);
		osc.start();
		lfo.start();
		lfo_freq_mod.start();
	}

	pageClass () {
		return 'ModularPage';
	}

	stopAudio = ()=> {
		this.ac.close();
	}

	renderBody () {
		let height = Eurorack.A100;
		let width = Eurorack.HP * 25;
		return (
			<div
				style={{
					display: 'inline-block',
					width: `${width}mm`,
					height: `${height}mm`,
					backgroundColor: 'green',
					verticalAlign: 'top'
				}}
			>
				<button
					onClick={this.stopAudio}
				>
					stop
				</button>
			</div>
		);
	}
}

export default ModularPage;
