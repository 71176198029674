import
	React,
	{ Component }
from 'react';
import { Link } from 'react-router-dom'

export default class LinksComponent extends Component {
	render () {
		let {
			links,
			className
		} = this.props;

		if (this.props.sort) {
			[...links].sort(this.sort);
		}

		let links_html = links.map(this.renderLink);

		return (
			<div
				className={className || 'Links'}
			>
				{links_html}
			</div>
		);
	}

	sort (a,b) {
		if (a.title < b.title) {
			return -1;
		}
		else if (a.title > b.title) {
			return 1;
		}
		else {
			return 0;
		}
	}

  renderLink = (link, index)=> {
		let {url, title} = link;
		let {onClick} = this.props;

    return (
			<Link
				onClick={onClick}
				className="Link"
				to={url}
				key={index}

			>
				{title}
			</Link>
		);
	}
}
