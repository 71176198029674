import React from 'react';

import {Page} from '../Core/';
import AsciiArt from '../Core/AsciiArtComponent';
import {
	randElement,
	randomColorAndComplement,
	rgb
} from '../Core/Utils';
import './Food.scss';

const FOODS = [
  'apple',
  'beer',
  'bread',
  'burger',
  'carrot',
  'cocacola',
  'eggplant',
  'eggs',
  'grapes',
  'icecream',
  'kiwi',
  'meat',
  'mushroom',
  'onion',
  'oranges',
  'peach',
  'pear',
  'peppers',
  'pepsi',
  'pineapple',
  'pizza',
  'potato',
  'salad',
  'strawberry',
  'tomato',
  'watermelon'
]

const CHANGE_FOOD_INTERVAL = 10000;

export default class FoodPage extends Page {
	constructor () {
		super();
		this.state = Object.assign({
			audio: false
		}, this.randomState());
	}

	pageClass () {
		return 'FoodPage';
	}

	componentDidMount () {
		setInterval(this.randomizeState, CHANGE_FOOD_INTERVAL)
	}

	randomState () {
		return {
			name: randElement(FOODS),
			name_colors: randomColorAndComplement(),
			food_colors: randomColorAndComplement()
		};
	}

	randomizeState = ()=> {
		let state = this.randomState();
		this.setState(state);
	}

	renderBody () {
		let {
			name_colors,
			food_colors,
			name
		} = this.state;

		let [bg_color, font_color] = name_colors.map(rgb);

		let src = this.getSrc();

		return (
	    <div
				className="food_container"
				onClick={this.playHorribleFoodSong}
				style={{
					backgroundColor: bg_color
				}}
			>
				<AsciiArt
					src={src}
					colors={food_colors}
				/>
	      <div
					className="name"
					style={{
						color: bg_color,
						textShadow: `5px 5px ${font_color}`
					}}
				>
	        {name}
	      </div>
	    </div>
  	);
	}

	getSrc () {
		let {name} = this.state;
	  return `/img/food/${name}.png`;
	}
}
