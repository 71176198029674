export default [
  {
    url_title   : 'arnold',
    slug        : 'youtube:PLFOT5D5kwz_805pzQv7DGFbqffPRI8cxM',
    title       : 'Arnold Previews',
    description : 'All of teh Arnolds'
  },
  {
    url_title   : 'hwga1',
    slug        : 'youtube:U3WzNfRFCvM',
    title       : 'Here We Go Again 1',
    description : 'HWGA1'
  },
  {
    url_title   : 'hwga2',
    slug        : 'youtube:yTzPA1JtTgE',
    title       : 'Here We Go Again 2',
    description : 'HWGA2'
  },
  {
    url_title   : 'butwerenottryingtosellit',
    slug        : 'youtube:K_h3FLSGHVw',
    title       : "But we're not trying to sell it (I guess we are)",
    description : 'ok'
  },
  {
    url_title   : 'awesomecampingtrip85',
    slug        : 'youtube:zAqIb6uMIBA',
    title       : "Awesome Camping Trip '85",
    description : 'ok'
  },
  {
    url_title   : 'latenighttalkshowhost',
    slug        : 'youtube:iYnKv6N4mnk',
    title       : 'Late Night Talk Show Host',
    description : 'ok'
  },
  {
    url_title   : '15minutesofimaginarycelebrities',
    slug        : 'youtube:PrbYzGjNV5s',
    title       : '15 minutes of imaginary celebrities',
    description : 'Imaginary 15'
  },
  {
    url_title   : '5minutesofimaginarycelebrities',
    slug        : 'youtube:9MAZnxvSWWA',
    title       : '5 minutes of imaginary celebrities',
    description : 'Imaginary 5'
  },
  {
    url_title   : '1minuteofimaginarycelebrities',
    slug        : 'youtube:i3AX-ivOk4Q',
    title       : '1 minute of imaginary celebrities',
    description : 'Imaginary 1'
  },
  {
    url_title   : 'sessionsincontemporaryart16yearsofmicrosoftfrontpage12',
    slug        : 'youtube:HjD-WXRKd_k',
    title       : 'SESSIONS IN CONTEMPORARY ART: 16 years of Microsoft FrontPage 12.0',
    description : 'My website jokes are the lowest form of stupid'
  },
  {
    url_title   : 'thatshimintheargylesweaterwith90smopcurls',
    slug        : 'youtube:4SL11AC2r3g',
    title       : 'That’s him in the argyle sweater with 90’s mop curls',
    description : 'Dat dapper pony wit da poncho'
  },
  {
    url_title   : 'bloodfallsfromtheceilingontodancingvampires',
    slug        : 'youtube:tJic1ogcm8k',
    title       : 'Blood falls from the ceiling onto dancing vampires',
    description : 'The world is a pumpkin'
  },
  {
    url_title   : 'superpepsis',
    slug        : 'youtube:gW8M4w6Sjvs',
    title       : 'Super Pepsis',
    description : 'They are very super'
  },
  {
    url_title   : 'coreyfeldmanandtheangelsatthecoachhouse',
    slug        : 'youtube:PwY5l4-Rp4I',
    title       : 'Corey Feldman & the Angels at The Coach House',
    description : 'Live!'
  },
  {
    url_title   : 'viadelavalledelmarheights84-16',
    slug        : 'youtube:XzP8KJBUKvc',
    title       : "Villa De La Valle / Del Mar Heights ('84 - '16)",
    description : 'Ok'
  },
  {
    url_title   : 'attenprank',
    slug        : 'youtube:PLFOT5D5kwz__5njSP1ugy_38Dce3MX3ce',
    title       : 'Attenprank',
    description : 'Just look at all the birds'
  },
  {
    url_title   : 'howtobuildyourownwebsite',
    slug        : 'youtube:PLFOT5D5kwz_8c3or5n2V6jfA5LqhjaYWq',
    title       : 'How To Build Your Own Website',
    description : 'All the steps you need'
  },
  {
    url_title   : 'hello',
    slug        : 'youtube:BwLJJAgPahk',
    title       : 'Hello',
    description : 'Its very easy'
  }
];
