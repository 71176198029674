import React from 'react';

export default class Z16SquareCanvas extends React.Component {
  constructor (props) {
    super(props);
    this.ref = React.createRef();
  }

  get dim () {
    const {square, scale} = this.props;
    const {size} = square;
    return size * scale;
  }

  render () {
    return (
      <canvas
        className="Z16SquareCanvas"
        ref={this.ref}
        width={this.dim}
        height={this.dim}
      />
    );
  }

  componentDidMount () {
    this.renderSquare();
  }

  componentDidUpdate () {
    this.renderSquare();
  }

  renderSquare () {
    const canvas = this.ref.current;
    const context = canvas.getContext('2d');
    const {square, scale} = this.props;
    // clear previous
    context.fillStyle = '#ffffff';
    const {dim} = this;
    context.fillRect(0, 0, dim, dim);
    square.render({context, scale, x: 0, y: 0});
  }
}
