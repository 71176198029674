import React, {useState, useEffect} from 'react';

import RalphWiggum from './RalphWiggum';

export default function RalphWiggumQuote () {
  const [quote, setQuote] = useState(RalphWiggum.random());

  useEffect(()=> {
    const ralph = new RalphWiggum();
    function updateQuote () {
      setQuote(ralph.next());
    }
    const interval = setInterval(updateQuote, 5000);
    return ()=> {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="RalphWiggumQuote">
      {quote}
    </div>
  )
}
