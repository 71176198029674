import React from 'react';
import styled from 'styled-components';

const CocoonsMummiesStyled = styled.div`
  margin: 10px;

  img {
    width: 320px;
    height: 240px;
  }

  .What {
    position: absolute;
    top: 5px;
    left: 345px;

    div {
      margin-bottom: 20px;
    }

    a {
      color: #228;
      text-decoration: none;
    }
  }
`;

export default function CocoonsMummiesPage () {
  return (
    <CocoonsMummiesStyled>
      <img src="/img/cocoon.jpg"/>
      <div className="What">
        <div>
          wherein a scale-replica of <a href="http://en.wikipedia.org/wiki/Wilford_Brimley">wilford brimley</a> is created out of <a href="http://en.wikipedia.org/wiki/Gummi_bear#Ingredients_and_production">gummy</a> and placed within an oatmeal <a href="http://en.wikipedia.org/wiki/Cocoon_(film)">cocoon</a> that has been partially cross-sectioned for some minimal acceptable level of visibility.
        </div>
        <div>
          This is presided over by an animatronic <a href="http://en.wikipedia.org/wiki/Steve_Guttenberg">steve guttenberg</a> puppet seated at a child's school desk roughly 15 meters away, with a guestbook facing away from him. the book is partially filled with droning analysis using words like [form, composition, palette, negative space, movement, rhythm, balance, unity, ...] except one page has only the phrase "cocoons, mummies" scrawled in a 5 year old child's handwriting at [2.5", 2", -15deg]. occasionally guttenberg will invite visitors to "please sign the guestbook" in Apple's Ralph voice. naturally, actual steve guttenberg (or a highly skilled impersonator) would be preferable to a puppet, but biological constraints will likely make that difficult.
        </div>
      </div>
    </CocoonsMummiesStyled>
  );
}
