import React from 'react';

import {
	FetchPage,
	Wikipedia
} from '../Core/';
import './Venery.scss';

export default class AnimalsPage extends FetchPage {
	renderLink ({title, name}, index) {
		return (
			<a
				className="AnimalLink"
				key={`${title}_${index}`}
				href={`/venery/${title}`}
			>
				{name}
			</a>
		);
	}

	renderReady () {
		let {animals} = this.state;
		return (
			<div className="Animals">
				{animals.map(this.renderLink)}
			</div>
		);
	}

	pageClass () {
		return 'AnimalsPage';
	}

	fetch () {
		return Wikipedia.fetchAnimals()
			.then((animals)=> {
				return {animals};
			});
	}
}
