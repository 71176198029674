import React from 'react';
import { Link } from 'react-router-dom'

import './Logo.scss';

export default function Logo () {
  return (
    <div className="Logo">
      <div className="LogoS">
        <div className="LogoS0 Front"/>

        <div className="LogoS1 Back"/>
        <div className="LogoS2 Front"/>
        <div className="LogoS3 Back "/>
        <div className="LogoS4 Front"/>
        <div className="LogoS5 Back"/>
      </div>
      <div className="LogoH">
        <div className="LogoH0 Back"/>
        <div className="LogoH1 Front"/>
        <div className="LogoH2 Back"/>
        <div className="LogoH3 Front"/>
      </div>
    </div>
  );
}

//
// $(document).ready(function () {
//   $('#contact').hover(
//     function () {
//       var self = $(this);
//
//       self.data('frontColor', self.find('.front').css('backgroundColor'));
//       self.data('backColor', self.find('.back').css('backgroundColor'));
//
//       function randomColor() {
//         var colors = [parseInt(Math.random() * 255), parseInt(Math.random() * 255), parseInt(Math.random() * 255)];
//         return 'rgb(' + colors.join(',') + ')';
//       }
//
//       function setRandomColors() {
//         var randomColors = [];
//         for (var i = 0; i < 10; i++) { randomColors[i] = randomColor();}
//         self.find('.front, .back').each(function(i, frontPart) {
//           $(frontPart).css('backgroundColor', randomColors[i]);
//         });
//       }
//
//
//       setRandomColors();
//
//       self.data('colorsTimeout', setInterval(setRandomColors, parseInt(Math.random() * 1000)));
//
//     },
//     function () {
//       var self = $(this),
//           frontColor = self.data('frontColor'),
//           backColor = self.data('backColor');
//
//       clearInterval(self.data('colorsTimeout'));
//       clearInterval(self.data('ralphTimeout'));
//
//       self
//         .find('.front')
//           .css('backgroundColor', frontColor)
//           .end()
//         .find('.back')
//           .css('backgroundColor', backColor);
//
//     }
//   );
// });
