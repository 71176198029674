import React from 'react';

import {
	Page,
	YouTubeSamplerComponent
} from '../Core/';
import './GrapeLady.scss';

const GRAPE_LADY = {
	name: 'grapelady',
	id: 'aMS0O3kknvk',
	keymap: {
		q: 1,
		w: 3,
		e: 8.5,
		r: 13.5,
		t: 16,
		y: 26,
		u: 43,
		i: 45,
		o: 47,
		p: 50,
		a: 52,
		s: 53,
		d: 54,
		f: 55,
		g: 56,
		h: 57,
		j: 58,
		k: 59,
		l: 60,
		z: 61,
		x: 62,
		c: 63,
		v: 64,
		b: 66,
		n: 69,
		m: 80
	}
};

export default class GrapeLadyPage extends Page {
  renderBody () {
    return (
			<YouTubeSamplerComponent {...GRAPE_LADY} />
    );
  }

  pageClass () {
    return 'GrapeLadyPage';
  }
}
