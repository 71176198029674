import React from 'react';

import {Page, Logo} from '../Core/';
import RalphWiggumQuote from './RalphWiggumQuote';
import './Hello.scss';

function Uses () {
  const uses = {
    GitHub: 'https://github.com/stephenhandley',
    LinkedIn: 'https://linkedin.com/in/stephenhandley',
    YouTube: 'https://youtube.com/stephenhandley',
    Spotify: 'https://open.spotify.com/user/stephen.handley',
    Twitch: 'https://twitch.com/stephenhandley',
    Instagram: 'https://instagram.com/stephenhandley',
    Quora: 'https://quora.com/Stephen-Handley',
    Facebook: 'https://facebook.com/stephenhandley',
    Twitter: 'https://twitter.com/stephenhandley',
    Vimeo: 'https://vimeo.com/stephenhandley',
    Flickr: 'https://flickr.com/photos/stephenhandley/',
  };

  const $uses = Object.entries(uses).map(([name, url])=> {
    return (
      <a
        className="ExternalLink"
        href={url}
        key={name}
      >
        {name}
      </a>
    );
  })

  return (
    <div className="Uses">
      {$uses}
    </div>
  );
}

export default class HelloPage extends Page {
  renderBody() {
    return (
      <div className="Background">
        <RalphWiggumQuote/>
        <Uses/>
      </div>
    );
  }

  pageClass () {
    return 'HelloPage';
  }
}
