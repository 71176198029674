import React from 'react';

import Page from './Page';

export default class FetchPage extends Page {
	state = {
		status: Status.Loading
	};

	succeeded = (data)=> {
		this.setState({
			...data,
			status: Status.Ready
		});
	}

	failed = (error)=> {
		this.setState({
			error,
			status: Status.Failed
		});
	}

	componentDidMount () {
		this.setState({
			status: Status.Loading
		});
		this.fetch()
			.then(this.succeeded)
			.catch(this.failed);
	}

	renderBody () {
		let {status} = this.state;
		if (!status) {
			return '';
		}
		return this[`render${status}`]();
	}

	renderLoading () {
		return (
			<div>...</div>
		);
	}

	renderFailed () {
		let {error} = this.state;
		console.error(error);
		return (
			<div>OOPS it not work.</div>
		);
	}

	renderReady () {
		throw new Error('Child should implement renderReady');
	}

	fetch () {
		throw new Error('Child should implement fetch');
	}
}

const Status = [
	'Loading',
	'Ready',
	'Failed'
].reduce((Status, name)=> {
	Status[name] = name;
	FetchPage.prototype[`is${name}`] = function () {
		return (this.state.status === name);
	};
	return Status;
}, {});


FetchPage.Status = Status;
