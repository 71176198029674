import
	React,
	{ Component }
from 'react';

export default class IframedPdfComponent extends Component {
	render () {
		const {
			url,
			title
		} = this.props;

		return (
			<div className="Pdf"
				style={{
					width: '100%',
					height: '100%'
				}}
			>
		    <iframe
					url={url}
					title={title}
					style={{
						width: '100%',
						height: '100%'
					}}
					frameBorder="0"
					scrolling="no"
				>
		    	<div>
						What the fuck kind of browser doesn't support iframes?
					</div>
		    </iframe>
			</div>
		);
	}
}
