import React from 'react';

import {
  Page,
  LinksComponent
} from '../Core/';
import Videos from './Videos';
import './Videos.scss';

export default class VideosPage extends Page {
  title () {
    return 'Video';
  }

  meta () {
    return {
      description: 'LOOK AT TEH MOVS'
    };
  }

  pageClass () {
    return 'VideosPage';
  }

  renderBody () {
    let links = Videos.map(({url_title, title})=> {
      let url = `/video/${url_title}`;
      return {url, title};
    });

    return (
      <LinksComponent
        links={links}
        sort={false}
      />
    );
  }
}
