import React from 'react';

import {
	randInt,
	randIntRange
} from '../Core/Utils';
import {
	FetchPage,
	Wikipedia
} from '../Core/';
import './Venery.scss';

const AUTOCHANGE_MAX = 4000;

const rand2to5 = randIntRange({
	min: 2,
	max: 5
});

export default class AnimalPage extends FetchPage {
	state = {
		autochange: null,
		index: {
			group: 0,
			image: 0
		}
	};

	urlTitle () {
		let {match: {params: {title}}} = this.props;
		return title;
	}

	title () {
		return `Venery: ${this.urlTitle()}`;
	}

	groupName () {
		let {
			animal,
			index
		} = this.state;

		return animal.group[index.group].toUpperCase();
	}

	pageClass () {
		return 'AnimalPage';
	}

	async fetch () {
		let title = this.urlTitle();

		let animal = await Wikipedia.fetchAnimal(title)

		let autochange = this.startRandomAutochange();

		let index = {
			images: randInt(animal.images.length),
			group: randInt(animal.group.length)
		};

		return {
			animal,
			index,
			autochange
		};
	}

	renderReady () {
		return (
			<div
				className="Animal"
				onClick={this.toggleAutoChange}
			>
				<div
					className="Background"
					style={this.backgroundStyle()}
				/>
				<div className="GroupName center">
					{this.groupName()}
				</div>
			</div>
		);
	}

	backgroundStyle () {
		let {
			animal,
			index
		} = this.state;

		return {
			backgroundImage: `url("${animal.images[index.images]}")`,
			animation: `spin ${rand2to5()}s infinite`
		};
	}

  change = ()=> {
		let {
			index,
			animal
		} = this.state;

		Object.keys(index).forEach((k)=> {
			let v = index[k];
			index[k] = ((v + 1) % animal[k].length);
		});

		this.setState({index});
	};

	toggleAutochange = ()=> {
		let {autochange} = this.state;
		if (autochange) {
			clearInterval(autochange);
			autochange = null;
		}
		else {
			this.change();
			autochange = this.startRandomAutochange()
		}
		this.setState({autochange});
	};

	startRandomAutochange () {
		let interval = randInt(AUTOCHANGE_MAX);
		return setInterval(this.change, interval);
	}
}
