import React from 'react';

import {
  Page,
} from '../Core/';
import './Home.scss';

class HomePage extends Page {
  title () {
    return 'SH';
  }

  pageClass () {
    return 'HomePage';
  }

  renderBody() {
    return (
      <h1 className="center">Nothing to see here</h1>
    );
  }
}

export default HomePage;
