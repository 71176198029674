import
	React,
	{ Component }
from 'react';

import {
  LinksComponent,
	Logo
} from '../Core/';
import './Nav.scss';

export default class NavComponent extends Component {
	state = {
		open: false
	};

	componentDidMount () {
		document.addEventListener('keydown', (event)=> {
			if (event.keyCode === 27) {
				event.preventDefault();
				this.toggleNav();
			}
		});

		document.addEventListener('click', ({target})=> {
			let nav = document.getElementsByClassName('Nav')[0];
			if (!nav.contains(target)) {
				this.setState({
					open: false
				});
			}
		});
	}

	render() {
		let {
      open
    } = this.state;

		let body = open ? this.renderOpen() : this.renderClosed();

    return (
			<div
				className='Nav'
			>
				{body}
			</div>
    );
  }

	renderClosed () {
		return (
			<div
				onClick={this.toggleNav}
				className='NavTriangle'
			></div>
		)
	}

	renderOpen () {
		let links = [
			{
				url   : '/shirts',
				title : 'Shirts'
			},
			{
				url   : '/gifs',
				title : 'GIFs'
			},
      {
        url   : '/trash',
        title : 'T R A S H'
      },
			{
				url   : '/people',
				title : 'People!'
			},
			{
				url   : '/stories',
				title : 'Stories'
			},
			{
				url   : '/they',
				title : 'They'
			},
			{
				url   : '/cocoonsmummies',
				title : 'Cocoons Mummies'
			},
			{
				url   : '/farleyclub',
				title : 'Farley Club'
			},
			{
				url   : '/dvd',
				title : 'DVD'
			},
			{
				url   : '/a_cheap_postcard_reproduction',
				title : 'A Cheap Postcard Reproduction'
			},
			{
				url   : '/wood',
				title : 'W O O D'
			},
      {
        url   : '/letsgotojapan',
        title : "Let's Go To Japan"
      },
      {
        url   : '/howtostopawedding',
        title : 'How To Stop A Wedding'
      },
			{
				url   : '/trafficschool',
				title : 'Traffic School'
			},
      {
        url   : '/venery',
        title : 'Venery'
      },
      {
        url   : '/food',
        title : 'Food'
      },
			{
				url   : '/STREAMLINEDAGENDAS',
				title : 'STREAMLINEDAGENDAS'
			},
			{
				url   : '/IMPORTANTTITLE',
				title : 'IMPORTANTTITLE'
			},
			{
				url   : '/giftastrophe',
				title : 'GIFTASTROPHE'
			},
			{
				url   : '/telegenics',
				title : 'Telegenics'
			},
			{
				url   : '/BEARBEARSTOPTHATSTOPTHATBEARSTOPTHATBEARBEARSTOPTHATBEARSTOPTHATBEARSTOPTHATPLEASESTOPTHATBEARBEARPLEASESTOPTHATPLEASESTOPBREAKINGMYTHINGS',
				title : 'BEAR! STOP THAT!'
			},
			{
				url   : '/grapelady',
				title : 'Grape Lady'
			},
			{
				url   : '/ui',
				title : 'UI'
			},
			{
				url   : '/48lightyears',
				title : '48 Light Years'
			},
			{
				url   : '/videos',
				title : 'Videos'
			},
			{
				url   : '/playlists',
				title : 'Playlists'
			},
			{
				url   : '/hello',
				title : <Logo/>
			}
    ];

		const close_html = (
			<div
				className="NavClose"
				onClick={this.toggleNav}
			>
				x
			</div>
		);

		return (
			<div
				className="NavContent"
			>
				{close_html}
				<LinksComponent
					links={links}
				  sort={false}
					onClick={this.toggleNav}
				/>
			</div>
		);
	}

	toggleNav = (event)=> {
		this.setState({
			open: !this.state.open
		});
	}
}
