import React, {useState} from 'react';
import styled from 'styled-components';

const GifsPageStyled = styled.div`
  .part1-small-2bit img {
    display: block;
  }
`;

export default function GifsPage () {
  const [index, setIndex] = useState(0);

  const images = [
    ['lukeperry2-2bit-lossy'],
    ['agape-2bit', 'agape-4bit'],
    ['dirtbikecops2'],
    ['livebraidagassi', 'livebraidagassi2'],
    ['part1-small-2bit', 'part1-small-4bit'],
    ['shipsterns'],
    ['bushbook1'],
    ['arkane']
  ]

  function next () {
    const next_index = (index + 1) % images.length;
    setIndex(next_index);
  }

  const $gifs = images[index].map((name)=> {
    return (
      <img
        key={name}
        src={`/img/gifs/${name}.gif`}
        className={name}
      />
    );
  })

  return (
    <GifsPageStyled>
      <div onClick={next}>
        {$gifs}
      </div>
    </GifsPageStyled>
  )
}
