import
	React,
	{ Component }
from 'react';
import {Helmet} from "react-helmet";

export default class Page extends Component {
	render () {
		return (
			<div className={this.pageClass()}>
				{this.renderHead()}
				{this.renderBody()}
			</div>
		)
	}

	pageClass () {
		//uglify in create-react-app fucks this up. see:
		//https://github.com/facebookincubator/create-react-app/issues/2876
		//return this.constructor.name;
		throw new Error('Child page should implement className');
	}

	title () {
		return 'Hello World';
	}

	meta () {
		return {};
	}

	renderHead () {
		let meta = this.meta();
		let meta_html = Object.keys(meta).map((name)=> {
			let content = meta[name];
			return (
				<meta
					key={name}
					name={name}
					content={content}
				/>
			)
		})

		return (
			<Helmet>
				<title>{this.title()}</title>
				{meta_html}
			</Helmet>
		);
	}
}
