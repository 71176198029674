import React from 'react';
import LazyLoad from 'react-lazyload';

import {Page} from '../Core/';
import './People.scss';
import TelegenicLoader from '../Telegenics/TelegenicLoader';

const IMAGE_COUNT = 608;
const IMAGE_WIDTH = 1024;
const IMAGE_HEIGHT = 1365;
const BUCKET_URL = 'https://firebasestorage.googleapis.com/v0/b/persondotsh.appspot.com/o';

export default class PeoplePage extends Page {
	renderImage (id) {
		let src = `${BUCKET_URL}/people%2F${id}.jpg?alt=media`;
		let width = window.innerWidth;
		let height = width * (IMAGE_HEIGHT / IMAGE_WIDTH);
		return (
			<LazyLoad
				key={id}
				width="100%"
				height={height}
				offset={height * 2}
				placeholder={<TelegenicLoader/>}
				resize={true}
			>
				<img
					className="Person"
					alt={`people_${id}`}
					src={src}
				/>
			</LazyLoad>
		);
	}

  renderBody () {
		let images = [];
		for (var i = 1; i <= IMAGE_COUNT; i++) {
			let image = this.renderImage(i);
			images.push(image);
		}
    return images;
  }

  pageClass () {
    return 'PeoplePage';
  }
}
