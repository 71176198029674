import $$ from 'cheerio';

export default class Wikipedia {
	static fetchArticle (title) {
		const url = `https://en.wikipedia.org/wiki/${title}`
		return fetch(url).then((response)=> {
			return response.text();
		});
	}

	static extractImages (html) {
		let imgs = [];
		$$.load(html)('img').each((i, img)=> {
			let src = $$(img).attr('src');
			let is_upload = (src.indexOf('upload.wikimedia.org/wikipedia') !== -1);
			let is_big = (parseInt(src.split('/').pop(), 10) > 100);
			if (is_upload && is_big) {
				imgs.push(`http:${src}`);
			}
		});
		return imgs;
	}

	static async fetchAnimals () {
		let html = await this.fetchArticle('List_of_English_terms_of_venery,_by_animal');
		return this.parseAnimalTable(html);
	}

	static async fetchAnimal (title) {
		let animals = await this.fetchAnimals();
		let animal = animals.find((animal)=> {
			return (animal.title === title)
		});

		if (!animal) {
			throw new Error("OH NO NO ANIMAL");
		}

		let animal_html = await this.fetchArticle(title);
		let images = await this.extractImages(animal_html);
		animal.images = images;

		return animal;
	}

	static parseAnimalTable (html) {
		let entries = [];

		let $trs = $$.load(html)('.wikitable tr');

		let i = 0;
		while (i < $trs.length) {
			let $tr = $$($trs[i]);
			i += 1;
			let $a = $tr.find('a');

			if ($a.length > 0) {
				let $td = $tr.find('td');
				let $td0 = $$($td[0]);
				let $td1 = $$($td[1]);
				let $a0 = $$($a[0]);

				let title = $a0.attr('href').split('/').pop();
				let name = $a0.text();
				let group = [$td1.text()];
				let entry = { title, name, group };

				let rowspan = parseInt($td0.attr('rowspan'), 10);
				if (rowspan > 1) {
					let rows = (rowspan - 1);
					for (let j = 0; j < rows; j++) {
						let $tr = $$($trs[i + j]);
						let $td0 = $$($tr.find('td')[0]);
						entry.group.push($td0.text());
					}
					i += rows;
				}

				entries.push(entry);
			}
		}

		return entries;
	}
}
