import React, {useState} from 'react';
import Styled from 'styled-components';
import {useAnimationFrame} from '@hello10/react-hooks';

// Adapted from https://codepen.io/stezu/pen/cmLrI
const DIMS = [153, 69];
const DVD_PATH = `M140.186,63.52h-1.695l-0.692,5.236h-0.847l0.77-5.236h-1.693l0.076-0.694h4.158L140.186,63.52L140.186,63.52z M146.346,68.756h-0.848v-4.545l0,0l-2.389,4.545l-1-4.545l0,0l-1.462,4.545h-0.771l1.924-5.931h0.695l0.924,4.006l2.078-4.006 h0.848V68.756L146.346,68.756z M126.027,0.063H95.352c0,0-8.129,9.592-9.654,11.434c-8.064,9.715-9.523,12.32-9.779,13.02 c0.063-0.699-0.256-3.304-3.686-13.148C71.282,8.7,68.359,0.062,68.359,0.062H57.881V0L32.35,0.063H13.169l-1.97,8.131 l14.543,0.062h3.365c9.336,0,15.055,3.747,13.467,10.354c-1.717,7.24-9.91,10.416-18.545,10.416h-3.24l4.191-17.783H10.502 L4.34,37.219h20.578c15.432,0,30.168-8.13,32.709-18.608c0.508-1.906,0.443-6.67-0.764-9.527c0-0.127-0.063-0.191-0.127-0.444 c-0.064-0.063-0.127-0.509,0.127-0.571c0.128-0.062,0.383,0.189,0.445,0.254c0.127,0.317,0.19,0.57,0.19,0.57l13.083,36.965 l33.344-37.6h14.1h3.365c9.337,0,15.055,3.747,13.528,10.354c-1.778,7.24-9.972,10.416-18.608,10.416h-3.238l4.191-17.783h-14.481 l-6.159,25.976h20.576c15.434,0,30.232-8.13,32.709-18.608C152.449,8.193,141.523,0.063,126.027,0.063L126.027,0.063z M71.091,45.981c-39.123,0-70.816,4.512-70.816,10.035c0,5.59,31.693,10.034,70.816,10.034c39.121,0,70.877-4.444,70.877-10.034 C141.968,50.493,110.212,45.981,71.091,45.981L71.091,45.981z M68.55,59.573c-8.956,0-16.196-1.523-16.196-3.365 c0-1.84,7.239-3.303,16.196-3.303c8.955,0,16.195,1.463,16.195,3.303C84.745,58.05,77.505,59.573,68.55,59.573L68.55,59.573z`;


const DVDPageStyled = Styled.div`
  background-color: black;
  height: 100%;
  color: white;

  svg {
    display: block;
  }
`;


export default function DVDPage () {
  return (
    <DVDPageStyled>
      <DVD
        speed={0.075}
        bounce_probability={0.9}
      />
    </DVDPageStyled>
  );
}

function randRange (range) {
  return Math.floor(Math.random() * range);
}

function randomColor () {
  const hue = randRange(360);
  return `hsl(${hue}, 100%, 50%)`;
}

function randomStartPosition () {
  const padding = 50;
  const [max_x, max_y] = maxPositions();
  const x = padding + randRange(max_x - padding);
  const y = padding + randRange(max_y - padding);
  return [x, y];
}

function maxPositions () {
  const [width, height] = DIMS;
  const max_x = window.innerWidth - width;
  const max_y = window.innerHeight - height;
  return [max_x, max_y];
}

function DVD ({speed, bounce_probability}) {
  const [width, height] = DIMS;
  const [start_x, start_y] = randomStartPosition();

  const [state, setState] = useState({
    x: start_x,
    y: start_y,
    dx: 1,
    dy: 1,
    bounce: true,
    fill: randomColor()
  });

  useAnimationFrame(({delta})=> {
    setState((prev_state) => {
      const {x, y, dx, dy, bounce, fill} = prev_state;

      const delta_x = speed * dx * delta;
      const delta_y = speed * dy * delta;
      let next_x = x + delta_x;
      let next_y = y + delta_y;

      let did_bounce = false;
      let next_dx = dx;
      let next_dy = dy;

      const [max_x, max_y] = maxPositions();

      if (bounce) {
        if (next_x >= max_x) {
          did_bounce = true;
          next_dx = dx * -1;
          next_x = max_x;
        }
        if (next_x <= 0) {
          did_bounce = true;
          next_dx = dx * -1;
          next_x = 0;
        }

        if (next_y >= max_y) {
          did_bounce = true;
          next_dy = dy * -1;
          next_y = max_y;
        }
        if (next_y <= 0) {
          did_bounce = true;
          next_dy = dy * -1;
          next_y = 0;
        }
      }

      let next_bounce = bounce
      let next_fill = fill;
      if (did_bounce) {
        next_bounce = (Math.random() < bounce_probability);
        next_fill = randomColor();
      }

      return {
        x: next_x,
        y: next_y,
        dx: next_dx,
        dy: next_dy,
        bounce: next_bounce,
        fill: next_fill
      };
    });
  });

  const {x, y, fill} = state;

  return (
    <svg
      width={width}
      height={height}
      style={{
        position: 'absolute',
        left: x,
        top: y,
        fill
      }}
    >
      <g>
        <path
          className="logo"
          d={DVD_PATH}
        />
      </g>
    </svg>
  );
}
