import React from 'react';

import {Page} from '../Core/';
import {
	randInt,
	mapRanges,
	euclideanDistance
} from '../Core/Utils';
import './Moustaches.scss';

export default class MoustachesPage extends Page {
  count = 50;

  offsets = {
    x: 1245 / 2835,
    y: 1180 / 2835
  };

  autochange_interval = {
    range : [10, 2000],
    start : 1500
  };

	constructor () {
		super();
		this.state = {
			autochange: true,
			interval: this.autochange_interval.start,
			index: randInt(this.count)
		};
	}

	pageClass () {
		return 'MoustachesPage';
	}

	componentWillMount () {
		this.repositionMoustache();
	}

  componentDidMount () {
		window.addEventListener('resize', this.repositionMoustache);
		this.change();
	}

	renderBody () {
		let {
			width,
			location
		} = this.state;

		if (!location) {
			return '';
		}

		let [x, top] = location;
		let left = (x - (width / 2));

		return (
      <div
				className="container"
				onMouseMove={this.onMouseMove}
			>
        <img
					alt="Mona Lisa"
					className="background"
					src="/img/moustaches/Mona_Lisa.jpg"
				/>
				<img
					alt="moustache"
					src={this.moustacheSrc()}
					className="moustache"
					onClick={this.toggleAutochange}
					style={{
						left,
						top,
						width
					}}
				/>
      </div>
		);
	}

	change = ()=> {
		let {
			autochange,
			interval
		} = this.state;

		this.next();

		if (autochange) {
			setTimeout(this.change, interval);
		}
	}

	next = ()=> {
		this.setState({
			index: ((this.state.index + 1) % this.count)
		});
	}

  toggleAutochange = ()=> {
		let autochange = !this.state.autochange;
		this.setState({autochange}, function () {
			if (autochange) {
				this.change();
			}
		});
	};

  repositionMoustache = ()=> {
		let width = window.innerWidth;
		let height = window.innerHeight;

		let location = [
			this.offsets.x * width,
			this.offsets.y * width
		];

    let m_width = width / 5;

		this.setState({
			width: m_width,
			location
		}, ()=> {
			let max_distance = this.findMaxDistance({width, height});
			this.setState({max_distance});
		});
	}

  moustacheSrc () {
    let index = this.state.index.toString();
    if (index.length < 2) {
      index = `0${index}`;
		}
    return `/img/moustaches/moustache_${index}.png`;
	}

  distance (point) {
    return euclideanDistance(point, this.state.location);
  }

  findMaxDistance = ({width, height})=> {
    let corners = [
      [ 0     , 0      ],
      [ 0     , height ],
      [ width , 0      ],
      [ width , height ]
    ];

    let max_distance = 0;
    for (let corner of corners) {
      let distance = this.distance(corner);
      if (distance > max_distance) {
        max_distance = distance;
			}
		}

    return max_distance;
	}

  onMouseMove = (event)=> {
    let point = [event.pageX, event.pageY]
    this.setChangeInterval(point);
	}

	setChangeInterval (point) {
		let {max_distance} = this.state;

    let distance = this.distance(point);

  	let interval = mapRanges({
      input  : [0, max_distance],
      output : this.autochange_interval.range,
      value  : distance
    })

		this.setState({interval});
	}
}

// thx http://www.pinkmoustache.net/new-free-set-of-50-moustache-vectors/
