import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import Color from '@hello10/color';

import Select from './Select';

const ColorChooserStyled = Styled.div`
  width: 200px;
`;

export default function ColorChooser ({color, setColor}) {
  const colors = Color.names().map((name)=> Color.fromName(name));

  function Option ({value: color}) {
    return (
      <div
        style={{
          color: 'white',
          backgroundColor: color.hex6,
          padding: '8px'
        }}
      >
        {color.name}
      </div>
    );
  }

  return (
    <ColorChooserStyled className="ColorChooser">
      <Select
        options={colors}
        isSelected={(c)=> c.equals(color)}
        onSelect={setColor}
        Option={Option}
        Selected={({value})=> value.name}
      />
    </ColorChooserStyled>
  );
}

ColorChooser.propTypes = {
  color: PropTypes.object,
  setColor: PropTypes.func
};
