import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import Color from '@hello10/color';

import {useTheme, ts} from './useTheme';
import {OutlineButton, SolidButton} from './Button';
import ColorChooser from './ColorChooser';

let color_name = window.location.pathname.split('/')[2];
if (!Color.nameExists(color_name)) {
  color_name = 'BerkeleyBlue';
}
const color = Color.fromName(color_name);

const UIPageStyled = Styled.div`
  padding-left: 180px;
  font-family: Helvetica Neue;
  font-weight: normal;

  background-color: ${ts('background100')};
  color: ${ts('foreground100')};

  h1 {
    margin-top: 0;
    font-size: 100px;
    margin-bottom: 10px;
  }

  .Button {
    display: block;
    margin-top: 10px;
  }
}
`;

const ColorStyled = Styled.div`
  margin-top: 10px;

  .ColorSwatch {
    border-radius: 2px;
    background-color: ${({color})=> color};
    width: 200px;
    height: 20px;
  }

  .ColorPercent {
    color: ${({color})=> color};
    font-size: 12px;
    margin-top: 2px;
  }
`;

function Buttons () {
  const theme = useTheme();
  return [SolidButton, OutlineButton].map((B, i)=> {
    return (
      <B
        key={i}
        onClick={theme.toggleMode}
      >
        {theme.dark ? 'Light' : 'Dark'}
      </B>
    );
  });
}

export default function UIPage () {
  const theme = useTheme({state: {color}});

  function Colors () {
    return theme.percents.map((percent)=> {
      const alpha = (percent / 100.0);
      const color = theme.foreground(alpha);
      return (
        <ColorStyled
          className="Color"
          key={percent}
          color={color}
        >
          <div className="ColorSwatch"></div>
          <div className="ColorPercent">{percent}</div>
        </ColorStyled>
      );
    });
  }

  return (
    <UIPageStyled
      className="UIPage"
      theme={theme}
    >
      <h1>Hello.</h1>
      <ColorChooser
        color={theme.color}
        setColor={theme.setColor}
      />
      <Buttons />
      <Colors />
    </UIPageStyled>
  );
}

UIPage.propTypes = {
  theme: PropTypes.object
};
