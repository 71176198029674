import React from 'react';

import {
  Page,
	IframedPdfComponent
} from '../Core/';

export default class ImportantTitlePage extends Page {
	renderBody () {
		let lol = 'IMPORTANTTITLE';
		return (
			<IframedPdfComponent
				title={lol}
				url={`${process.env.PUBLIC_URL}/pdf/${lol}.pdf`}
			/>
		)
	}

  pageClass () {
    return 'ImportantTitlePage';
  }
}
