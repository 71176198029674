import {Page} from '../Core/';
import './Insta.scss'

class InstaPage extends Page {
  title () {
		return "Insta";
	}

	meta () {
		return {
			description: "How 2 delete yr instagram"
		};
	}

	pageClass () {
		return 'Insta';
	}

	renderBody() {
    return (
			<a href="https://gist.github.com/stephenhandley/171b32e298c4ae0f0787d15e30192ae4">how 2 delete yr instagrams</a>
		)
  }
}

export default InstaPage;