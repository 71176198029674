import Z16 from './Z16';

const maps = {
  i: Z16,
  t: [
    15, 15, 15, 15,
    15, 15, 15, 15,
    15, 15, 15, 15,
    15, 15, 15, 15
  ],
  f: [
     0,  0,  0,  0,
     0,  0,  0,  0,
     0,  0,  0,  0,
     0,  0,  0,  0
  ],
  inc: [
     1,  2,  3,  4,
     5,  6,  7,  8,
     9, 10, 11, 12,
    13, 14, 15,  0
  ],
  dec: [
    15,  0,  1,  2,
     3,  4,  5,  6,
     7,  8,  9, 10,
    11, 12, 13, 14
  ],
  not: [
    15, 14, 13, 12,
    11, 10,  9,  8,
     7,  6,  5,  4,
     3,  2,  1,  0
  ],
  m0: [
     0,  2,  1,  3,
     8, 10,  9, 11,
     4,  6,  5,  7,
    12, 14, 13, 15
  ],
  m45: [
     0,  8,  2, 10,
     4, 12,  6, 14,
     1,  9,  3, 11,
     5, 13,  7, 15
  ],
  m90: [
     0,  4,  8, 12,
     1,  5,  9, 13,
     2,  6, 10, 14,
     3,  7, 11, 15
  ],
  m135: [
     0,  1,  4,  5,
     2,  3,  6,  7,
     8,  9, 12, 13,
    10, 11, 14, 15
  ],
  r90: [
     0,  2,  8, 10,
     1,  3,  9, 11,
     4,  6, 12, 14,
     5,  7, 13, 15
  ],
  r180: [
     0,  8,  4, 12,
     2, 10,  9, 14,
     1,  6,  5, 13,
     3, 11,  7, 15
  ],
  r270: [
     0,  4,  1,  5,
     8, 12,  9, 13,
     2,  6,  3,  7,
    10, 14, 11, 15
  ]
};

function mapper (name) {
  if (!(name in maps)) {
    throw new Error(`No map defined for ${name}`);
  }
  const mapping = maps[name].map((val, index)=> [index, val]);
  const map = new Map(mapping);
  return function (num) {
    return map.get(num);
  };
}

const i = mapper('i');
const t = mapper('t');
const f = mapper('f');
const not = mapper('not');
const inc = mapper('inc');
const dec = mapper('dec');
const m0 = mapper('m0');
const m45 = mapper('m45');
const m90 = mapper('m90');
const m135 = mapper('m135');
const r90 = mapper('r90');
const r180 = mapper('r180');
const r270 = mapper('r270');

export {
  i,
  t,
  f,
  not,
  inc,
  dec,
  m0,
  m45,
  m90,
  m135,
  r90,
  r180,
  r270
}
