import React from 'react';

import {Page} from '../Core/';
import TelegenicLoader from './TelegenicLoader';
import './Telegenics.scss';

// lol http://telegenics.net/telegenics.html

export default class TelegenicsPage extends Page {
	title () {
		return 'Telegenics';
	}

	renderAlphanums () {
		let alphanums = `1A, 1B, 2A, 2B, 3A, 3B, 3C, 4A, 4B, 5A, 5B, 6A, 6B, 7A, 7B, 8A, 8B, 9A, 9B, 10A, 10B, 11A, 11B, 12A, 12B, 13A, 13B, 14A,
14B, 15A, 15B, 16A, 16B, 17A, 17B, 18A, 18B, 19A, 19B, 20A, 20B, 21A, 21B, 22A, 22B, 23A, 23B, 24A, 24B, 25A, 25B,
26A, 26B, 27A, 27B, 27C, 28A, 28B, 28C, 29A, 29B, 29C, 30A, 30B, 30C, 31A, 31B, 31C, 32A, 32B, 32C, 33A, 33B, 33C,
33D, 34A, 34B, 34C, 35A, 35B, 35C, 36A, 36B, 36C, 37A, 37B, 37C, 38A, 38B, 38C, 39A, 39B, 39C, 40A, 40B, 40C, 41A,
41B, 41C, 42A, 42B, 42C, 43A, 43B, 43C, 44A, 44B, 44C, 45A, 45B, 45C, 46A, 46B, 46C, 47A, 47B, 47C, 48A, 48B, 48C,
49A, 49B, 49C, 50A, 50B, 50C, 51A, 51B, 51C, 52A, 52B, 52C, 53A, 53B, 53C, 53D, 54A, 54B, 54C, 54D, 55A, 55B, 55C,
55D, 56A, 56B, 56C, 57B, 57C, 57D, 58A, 58B, 58D, 59A, 59B, 59C, 60A, 60B, 60C, 60D, 60F, 61A, 61B, 61C, 61D, 62A,
62B, 62C, 62D, 63A, 63B, 63C, 63D, 64A, 64B, 64C, 64D, 64H, 65A, 65B, 65C, 65F, 66A, 66B, 66C, 67A, 67B, 67C, 67D,
68A, 68B, 68C, 68F, 69A, 69B, 69C, 69D, 69F, 70A, 70B, 70C, 70D, 71A, 71B, 71C, 71D, 71E, 71H, 72A, 72B, 72C, 72D,
73A, 73B, 73C, 73D, 73F, 74A, 74B, 74C, 74D, 74E, 75A, 75B, 75C, 75D, 76A, 76B, 76C, 76D, 77A, 77B, 77C, 77D, 77H,
78A, 78B, 78C, 78D, 79A, 79B, 79C, 79D, 79E, 80A, 80B, 80C, 80D, 81A, 81B, 81C, 81D, 82A, 82B, 82C, 82D, 83A, 83B,
83C, 83D, 83E, 84A, 84B, 84C, 84D, 85A, 85B, 85C, 85D, 86A, 86B, 86C, 86D, 87A, 87B, 87C, 87D, 88A, 88B, 88C, 88D,
89A, 89B, 89C, 89D, 90A, 90B, 90C, 90D, 91A, 91B, 91C, 91D, 91K1, 91K2, 92A, 92B, 92C, 92D, 92K1, 92K2, 93A, 93B,
93C, 93D, 94A, 94B, 94C, 94D, 95A, 95B, 95C, 95D, 96A, 96B, 96C, 96D, 97A, 97B, 97C, 97D, 98A, 98B, 98C, 98D, 99A,
99B, 99C, 99D, 100A, 100B, 100C, 100D, 101A, 101B, 101C, 101D, 102A, 102B, 102C, 102D, 103A, 103B, 103C, 103D,
104A, 104B, 104C, 104D, 104G, 105A, 105B, 105C, 105D, 105G, 106A, 106B, 106C, 106D, 106G, 107A, 107B, 107C,
107D, 107G, 108A, 108B, 108C, 108D, 108G, 109A, 109B, 109C, 109D, 109G, 110A, 110B, 110C, 110D, 110G, 111A,
111B, 111C, 111D, 111G, 112A, 112B, 112C, 112D, 112G, 113A, 113C, 113D, 113G, 114A, 114B, 114C, 114D, 114G,
115A, 115B, 115C, 115D, 115G, 116A, 116B, 116C, 116D, 116G, 117A, 117B, 117C, 117D, 117G, 118A, 118B, 118C,
118D, 118G, 119A, 119B, 119C, 119D, 119G, 120A, 120B, 120C, 120D, 120G, 121A, 121B, 121C, 121D, 121G, 122A,
122B, 122C, 122D, 122G, 123A, 123B, 123C, 123D, 123G, 124A, 124B, 124C, 124D, 124G, 125A, 125B, 125C, 125D,
125G, 126A, 126B, 126C, 126D, 126G, 127A, 127C, 127D, 127G, 128A, 128B, 128C, 128D, 128G, 129A, 129B, 129C,
129D, 129G, 130A, 130B, 130C, 130D, 130G, 131A, 131B, 131C, 131D, 131G, 132A, 132C, 132D, 132G, 133A, 133B,
133C, 133D, 133G, 134A, 134B, 134C, 134D, 134G, 135A, 135B, 135C, 135D, 135G, 136A, 136B, 136C, 136D, 136G,
137A, 137B, 137C, 137D, 138A, 138B, 138C, 138D, 139A, 139C, 139D, 140A, 140C, 140D, 141A, 141B, 141C, 141D,
PR1, PR2, PR3, PR5, PR6, PR8, PR9, PR10, PR11, PR12, PR13, PR14, PR15, PR16, PR17, PR18, PR19, PR20,
TPC01, TPM01, H3, H7`.split(',').map((s)=> s.trim());

		return alphanums.map((a)=> {
			return (
				<a
					key={a}
					href={`/telegenics/${a}`}
				>
					{a}
				</a>
			);
		});
	}

	renderBody () {
		return (
			<div className="telegenics_container">
				<div className="history">
					{">>> Here I have actually, a piece of history of the 1980s & 1990s decades in the music on video <<<"}
				</div>
				<div className="scroll">
					*YOU CAN SCROLL DOWN FOR ALMOST THE COMPLETE SERIES OF TELEGENICS 	*
				</div>
				<div className="loading">
					<span>THIS PAGE MAY TAKE SOME TIME TO LOAD IN FULL…</span>
					<TelegenicLoader />
				</div>
				<div className="reels">
					<div className="reel">
						<div className="reel_spaced">
							T E L E G E N I C S<span className="yesspacerun">&nbsp;&nbsp; </span>
							U S A
						</div>
						<div className="reel_count">
							548 Reels on this series
						</div>
					</div>
					<div className="reel">
						<div className="reel_spaced">
							T E L E G E N I C S<span className="yesspacerun">&nbsp;&nbsp; </span>
							C A N A D A
						</div>
						<div className="reel_count">
							30 Reels on this series
						</div>
					</div>
				</div>
				<div className="no_payoff">
					<div className="alphanums">
						{this.renderAlphanums()}
					</div>
					<div className="totals">
						<span>TOTAL OF</span>
						<span className="font548">548</span>
						<span className="font17">TELEGENICS USA &amp;</span>
						<span className="font46">30</span>
						<span className="font17">TELEGENICS CANADA</span>
						<span class="font51">LISTED</span>
					</div>
				</div>
			</div>
		)
	}

	pageClass () {
		return 'TelegenicsPage';
	}
}
