import
	React,
	{ Component }
from 'react';

import {
  BrowserRouter,
  Route,
	Routes
} from 'react-router-dom'

import {
	AnimalPage,
	AnimalsPage,
	BearStopThatPage,
	CocoonsMummiesPage,
	DVDPage,
	FarleyPage,
	FoodPage,
	FortyEightLightYearsPage,
	GifsPage,
	GiftastrophePage,
	GrapeLadyPage,
	HelloPage,
  HomePage,
	HowToStopAWeddingPage,
	ImportantTitlePage,
	InstaPage,
  LetsGoToJapanPage,
  ModularPage,
	MoustachesPage,
	NotFoundPage,
	PeoplePage,
	PlaylistsPage,
  ShirtsPage,
	SquarePage,
	StoriesPage,
	StreamlinedAgendasPage,
	TelegenicPage,
	TelegenicsPage,
	TheyPage,
	TrafficSchoolPage,
	TrashPage,
	UIPage,
	VideoPage,
	VideosPage,
	WoodPage
} from './pages';
import Nav from './Nav';
//import logo from './logo.svg';
import './App.scss';

class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <div className="App">
          <Nav/>
					<Routes>
	          <Route
							exact
							path="/"
							element={<HomePage />}
						/>
						<Route
							path="/square/:pattern?"
							element={<SquarePage />}
						/>
						<Route
							path="/food"
							element={<FoodPage />}
						/>
						<Route
							path="/gifs"
							element={<GifsPage />}
						/>
						<Route
							path="/BEARBEARSTOPTHATSTOPTHATBEARSTOPTHATBEARBEARSTOPTHATBEARSTOPTHATBEARSTOPTHATPLEASESTOPTHATBEARBEARPLEASESTOPTHATPLEASESTOPBREAKINGMYTHINGS"
							element={<BearStopThatPage />}
						/>
						<Route
							path="/they"
							element={<TheyPage />}
						/>
						<Route
							path="/cocoonsmummies"
							element={<CocoonsMummiesPage />}
						/>
						<Route
							path="/farleyclub"
							element={<FarleyPage />}
						/>
						<Route
							path="/trafficschool"
							element={<TrafficSchoolPage />}
						/>
						<Route
							path="/hello"
							element={<HelloPage />}
						/>
						<Route
							path="/stories"
							element={<StoriesPage />}
						/>
						<Route
							path="/dvd"
							element={<DVDPage />}
						/>
						<Route
							path="/wood"
							element={<WoodPage />}
						/>
						<Route
							path="/grapelady"
							element={<GrapeLadyPage />}
						/>
	          <Route
							path="/letsgotojapan"
							element={<LetsGoToJapanPage />}
						/>
						<Route
							path="/people"
							element={<PeoplePage />}
						/>
	          <Route
							path="/modular"
							element={<ModularPage />}
						/>
						<Route
							path="/playlists"
							element={<PlaylistsPage />}
						/>
						<Route
							path="/a_cheap_postcard_reproduction"
							element={<MoustachesPage />}
						/>
	          <Route
							path="/shirts"
							element={<ShirtsPage />}
						/>
						<Route
							path="/howtostopawedding"
							element={<HowToStopAWeddingPage />}
						/>
						<Route
							path="/STREAMLINEDAGENDAS"
							element={<StreamlinedAgendasPage />}
						/>
						<Route
							path="/IMPORTANTTITLE"
							element={<ImportantTitlePage />}
						/>
						<Route
							path="/giftastrophe"
							element={<GiftastrophePage />}
						/>
						<Route
							path="/telegenics/:title"
							element={<TelegenicPage />}
						/>
						<Route
							path="/telegenics"
							element={<TelegenicsPage />}
						/>
						<Route
							path="/trash"
							element={<TrashPage />}
						/>
						<Route
							path="/ui"
							element={<UIPage />}
						/>
						<Route
							exact
							path="/venery"
							element={<AnimalsPage />}
						/>
						<Route
							path="/venery/:title"
							element={<AnimalPage />}
						/>
						<Route
							path="/videos"
							element={<VideosPage />}
						/>
						<Route
							path="/video/:title"
							element={<VideoPage />}
						/>
						<Route
							path="/48lightyears"
							element={<FortyEightLightYearsPage />}
						/>
						<Route
							path="/insta"
							element={<InstaPage />}
						/>
						<Route
							element={<NotFoundPage />}
						/>
					</Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
