import * as React from 'react';
import _ from 'lodash';
import Avatar from 'avataaars';

import options from './options';

function remapEntries (object, op) {
  return Object.entries(object).reduce((result, entry)=> {
    const output = op(entry);
    const [key] = entry;
    result[key] = output;
    return result;
  }, {});
}

function should (threshold) {
  const test = Math.random();
  return (test < threshold);
}

function randomIntegerUnder (n) {
  return Math.floor(n * Math.random());
}

export default class TheyPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      iteration: 1
    };
  }

  render () {
    const floor = randomIntegerUnder(4) + 5;
    const times = randomIntegerUnder(5) + 5;
    const intervals = _.times(times, (i)=> Math.pow(2, i + floor));

    function randomInterval () {
      const index = randomIntegerUnder(intervals.length)
      return intervals[index];
    }

    return (
      <They
        iteration={this.state.iteration}
        config={{
          topType: {
            interval: randomInterval(),
            reverse: 0.7,
            jump: 0.3
          },
          accessoriesType: {
            interval: randomInterval(),
            reverse: 0.8,
            blank: 0.8,
            jump: 0.7
          },
          hatColor: {
            interval: randomInterval(),
            reverse: 0.8,
            jump: 0.3
          },
          hairColor: {
            interval: randomInterval(),
            reverse: 0.8,
            jump: 0.4
          },
          facialHairType: {
            interval: randomInterval(),
            reverse: 0.5,
            jump: 0.5,
            blank: 0.4
          },
          facialHairColor: {
            interval: randomInterval(),
            reverse: 0.2,
            jump: 0.5
          },
          clotheType: {
            interval: randomInterval(),
            reverse: 0.3,
            jump: 0.6
          },
          clotheColor: {
            interval: randomInterval(),
            reverse: 0.8,
            jump: 0.3
          },
          eyeType: {
            interval: randomInterval(),
            reverse: 0.5,
            jump: 0.5
          },
          eyebrowType: {
            interval: randomInterval(),
            reverse: 0.5,
            jump: 0.5
          },
          mouthType: {
            interval: randomInterval(),
            reverse: 0.5,
            jump: 0.5
          },
          skinColor: {
            interval: randomInterval(),
            reverse: 0.5,
            jump: 0.5
          }
        }}
      />
    );
  }

  componentDidMount () {
    this.interval = window.setInterval(()=> {
      let {iteration} = this.state;
      iteration++;
      this.setState({iteration});
    }, Math.pow(2, 13)); // 8096
  }

  componentWillUnmount () {
    window.clearInterval(this.interval);
  }
}


class They extends React.Component {
  constructor (props) {
    super(props);
    this.state = remapEntries(options, ([k, v])=> randomIntegerUnder(v.length));
    this.intervals = [];
    this.directions = remapEntries(options, ()=> 1);
  }

  componentDidMount () {
    this.setupSteppers();
  }

  componentDidUpdate (prevProps) {
    if (this.props.iteration > prevProps.iteration) {
      this.setupSteppers();
    }
  }

  setupSteppers () {
    const {config} = this.props;
    this.clearIntervals();
    this.intervals = Object.keys(options).map((field)=> {
      const field_config = config[field];
      return this.stepper({field, ...field_config});
    });
  }

  componentWillUnmount () {
    this.clearIntervals();
  }

  clearIntervals () {
    for (const interval of this.intervals) {
      window.clearInterval(interval);
    }
  }

  stepper ({field, interval, reverse, jump, blank}) {
    return window.setInterval(()=> {
      const {length} = options[field];

      let index;
      if (jump && should(jump)) {
        index = randomIntegerUnder(length);
      } else if (blank && should(blank)) {
        index = 0;
      } else {
        let direction = this.directions[field];
        if (should(reverse)) {
          direction = direction * -1;
        }
        this.directions[field] = direction;
        index = this.state[field] + direction;
      }

      if (index < 0) {
        index = length - 1;
      }
      if (index >= length) {
        index = 0;
      }

      this.setState({
        [field]: index
      });
    }, interval);
  }

  render () {
    const {innerHeight, innerWidth} = window;
    const size = Math.min(innerHeight, innerWidth) * 0.75;
    const avatar_values = this.getValues();
    return (
      <Avatar
        style={{
          position: 'absolute',
          bottom: '0px',
          right: '10%',
          width: `${size}px`,
          height: `${size}px`
        }}
        avatarStyle='Transparent'
        {...avatar_values}
      />
    );
  }

  getValues () {
    return remapEntries(this.state, ([k, v])=> options[k][v]);
  }
}
