// ...
// 10 => 1010
// 11 => 1011
// ...
export default function toQuartet (int) {
  let quartet = (int >>> 0).toString(2);
  while (quartet.length < 4) {
    quartet = '0' + quartet;
  }
  return quartet;
}
