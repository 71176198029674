import React from 'react';

export default function () {
	return (
		<img
			alt="telegenics"
			src="/img/telegenics.gif"
		/>
	);
}
