import * as Operators from './Operators';

export default function randomOp () {
  const names = Object.keys(Operators).filter((name)=> {
    return !['t', 'f', 'i'].includes(name);
  });
  const index = Math.floor(Math.random() * names.length);
  const name = names[index];
  return Operators[name];
}
