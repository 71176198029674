import React from 'react';

import {Page} from '../Core/';
import './LetsGoToJapan.scss';

const JAPAN_RED = '#ed2939';
const TARGET_RATIO = 3/2;
const MAX_DELTA = 0.05;

class LetsGoToJapanPage extends Page {
	state = {
		been_to_japan: false
	};

	componentDidMount () {
		this.resize = window.addEventListener('resize', this.onResize, false);
		this.onResize();
	}

	componentWillUnmount () {
		window.removeEventListener(this.resize);
	}

	title () {
		return "Let's Go To Japan";
	}

	meta () {
		return {
			description: "Go change the size of your browser window on your desktop computer ok?"
		};
	}

	pageClass () {
		return 'LetsGoToJapanPage';
	}

	renderBody() {
		let {
			text,
			font_size,
			font_color,
			flag_color,
			circle_color,
			circle_size
		}  = this.state;

		return (
			<div
				ref="flag"
				className="Flag center"
				style={{
					backgroundColor: flag_color
				}}
			>
	      <div
					className="Circle"
					onClick={this.resize}
					style={{
						width: circle_size,
						height: circle_size,
						borderRadius: `${circle_size / 2}px`,
						backgroundColor: circle_color
					}}
				>
	        <div
						className="TextWrap center"
					>
	          <div
							className="Text"
							style={{
								fontSize: font_size,
								color: font_color
							}}
						>
							{text}
						</div>
	        </div>
	      </div>
	    </div>
		);
	}

	resize = ()=> {
		let between500And1000 = ()=> {
			return (((Math.random() * 0.5) + 0.5) * 1000);
		}

		let delta = (size)=> {
			return ((Math.random() * (size * 2)) - size);
		}

		let chrome_height = (window.outerHeight - window.innerHeight);
		let width  = between500And1000();
		let height = (width / TARGET_RATIO);
		width  = ((height - chrome_height) * (TARGET_RATIO + delta(0.2)))

		this.onResize();
	}

	onResize = ()=> {
    let height = window.innerHeight;
    let width  = window.innerWidth;

    let circle_size = (height * 3/5);

    let ratio = (width / height);
    let ratio_delta = Math.abs(ratio - TARGET_RATIO);
    let right_ratio = (ratio_delta < MAX_DELTA);

    let small_font_size = (circle_size / 11);
		let large_font_size = (circle_size / 14);

		if (right_ratio) {
      this.setState({
				been_to_japan: true,
				text: '',
				font_color: JAPAN_RED,
				font_size: small_font_size,
				flag_color: 'white',
				circle_color: JAPAN_RED,
				circle_size
			});
		}
    else {
			let text;
			let font_size;

      if (this.state.been_to_japan) {
				text = "LET'S GO BACK TO JAPAN";
        font_size = large_font_size;
			}
      else {
				text = "LET'S GO TO JAPAN";
        font_size = small_font_size;
			}

			this.setState({
				text,
				font_size,
				font_color: 'black',
				flag_color: 'black',
				circle_color: 'white',
				circle_size
			});
		}
	}
}

export default LetsGoToJapanPage;
