import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {range} from './Core';

const FarleyStyled = styled.a`
  .Images {
    position: absolute;
    top: 15px;
    left: 55px;
    z-index: 111111111;

    img {
      width: 350px;
      height: 350px;
    }

    img:not(.Current) {
      display: none;
    }
  }

  .What {
    position: absolute;
    top: 5px;
    left: 525px;
  }
`;

function FarleyImages () {
  const num_farleys = 5;
  const [index, setIndex] = useState(1);

  function next () {
    let next_index = index + 1;
    if (next_index > num_farleys) {
      next_index = 1;
    }
    setIndex(next_index);
  }

  useEffect(()=> {
    const interval = setInterval(next, 10000);
    return ()=> {
      clearInterval(interval);
    };
  }, []);

  const $images = range(1, num_farleys).map((i)=> {
    const class_names = ['Image'];
    if (i === index) {
      class_names.push('Current');
    }
    return (
      <img
        src={`/img/farley/${i}.gif`}
        className={class_names.join(' ')}
      />
    );
  });

  return (
    <div className="Images">
      {$images}
    </div>
  );
}

export default function GiftastrophePage () {
  return (
    <FarleyStyled>
      <FarleyImages/>
      <iframe
        width="500"
        height="300"
        src="https://www.youtube.com/embed/SgM3r8xKfGE?autoplay=1"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="false"
      ></iframe>
      <div className="What">
        wherein a photorealistic 3d model of chris farley is created for and inserted into the music video for "club can't handle me" by Flo Rida (feat. "David Guetta"). the plot is reorganized around a middle aged advertising executive reluctantly going to a "club" after "younger employees" convince him to accompany them to do market research for some sort of "new beverage".
      </div>
    </FarleyStyled>
  );
}
