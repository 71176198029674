import React from 'react';

import {Page} from '../Core/';
import './BearStopThat.scss';

export default class BearStopThatPage extends Page {
  renderBody() {
    return (
			<marquee
				scrollamount="100"
			>
				<a href="https://www.youtube.com/watch?v=nU5cMZymSr0">
					THANK YOU FOR LEAVING MY KAYAK ALONE. I'M GOING TO PEPPER SPRAY YOU IN THE FACE. THAT'S WHAT I'M GOING TO DO TO YOU. [PEPPER SPRAY] GO AWAY! NO, GET AWAY FROM THE KAYAK! GET AWAY FROM THAT KAYAK! COME HERE! COME ON. STOP IT BEAR, STOP IT. BEAR! BEAR! BEAR! YOU'RE BREAKING IT, YOU'RE BREAKING MY KAYAK. WHY ARE YOU DOING THAT? WHY ARE YOU BREAKING MY KAYAK? WHAT ARE YOU BREAKING MY KAYAK!? WHAT AM I GONNA DO!? WHY ARE YOU BREAKING MY KAYAK!? STOP IT! BEAR! STOP THAT! STOP THAT, BEAR! BEAR, STOP!! STOP BREAKING MY KAYAK, PLEASE!? PLEASE STOP! GOSH DARNIT. OHHHH WHY ARE YOU DOING THAT? BEAR, PLEASE STOP! PLEASE STOP, BEAR! IT'S THE END OF SEPTEMBER. WHY ARE YOU HERE? YOU'RE SUPPOSED TO BE ASLEEP. WHY ARE YOU HERE? AHHHHH. BEAR! BEAR! STOP THAT! STOP THAT! BEAR, STOP THAT! BEAR!! BEAR, STOP THAT! BEAR STOP THAT! BEAR STOP THAT PLEASE STOP THAT BEAR? BEAR PLEASE STOP THAT, PLEASE STOP BREAKING MY THINGS. AH NO PLEASE STOP BREAKING MY THINGS, BEAR. BEAR!? PLEASE STOP BREAKING MY THINGS. IT'S NOT IT'S NOT EVEN FOOD IT DOESN'T EVEN TASTE GOOD IT’S JUST PLASTIC! BEAR PLEASE STOP! PLEASE STOP BEAR! BEAR PLEASE STOP! PLEASE STOP THAT BEAR! BEAR I DON'T CARE I'M GONNA BEAR SPRAY YOU PLEASE STOP! BEAR! BEAR! BEAR! BEAR! BEAR PLEASE STOP!
				</a>
			</marquee>
    );
  }

  pageClass () {
    return 'BearStopThatPage';
  }
}
