import
	React,
	{ Component }
from 'react';

export default class VideoComponent extends Component {
	static defaultProps = {
		autoplay: false,
		controls: true,
		loop: false
	};

  componentDidMount () {
		let {size} = this.props;
		let {iframe} = this;

		function fullscreen () {
			iframe.width = window.innerWidth;
			iframe.height = window.innerHeight;
		}

    if (size === 'FULLSCREEN') {
			fullscreen();
			window.addEventListener('resize', fullscreen);
		}
	}

  playerUrl () {
		let {
			slug,
			autoplay,
			controls,
			loop
		} = this.props;

    let [type, id] = slug.split(':');

		function boolToInt (val) {
			return val ? 1 : 0;
		}

    switch (type) {
      case 'youtube':
				controls = boolToInt(controls);
				autoplay = boolToInt(autoplay);
				loop = boolToInt(loop);

        let path = (id.length > 11) ? `videoseries?list=${id}&` : `${id}?`;
        return `https://www.youtube.com/embed/${path}autoplay=${autoplay}&controls=${controls}&enablejsapi=1&loop=${loop}&showinfo=0&autohide=1`;

      case 'vimeo':
        return `https://player.vimeo.com/video/${id}`;

      default:
        throw new Error("Unsupported video type");
		}
	}

  render () {
		let {
			size,
			title
		} = this.props;

		let width;
		let height;

		if (size === 'FULLSCREEN') {
      [width, height] = [500, 300];
    }
		else {
      [width, height] = size;
		}

    let url = this.playerUrl();

    return (
    	<iframe
				ref={(iframe)=> {
					this.iframe = iframe;
				}}
				type="text/html"
				width={width}
				height={height}
				title={title}
				src={url}
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen="true"
			></iframe>
    );
	}
}
