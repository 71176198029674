import React from 'react';

import {
  Page,
} from '../Core/';
import './Trash.scss';

export default class TrashPage extends Page {
  renderBody () {
		let trash = [];

		let trash_html = trash.map(this.renderItem);

    return (
			<div
				className="Trash"
			>
				where u can get some trash
				{trash_html}
			</div>
    );
  }

	renderItem (piece) {
		let {
			image_url,
			title,
			materials
		} = piece;

		return (
			<div
				className="Piece"
			>
				<img
          alt={title}
					src={image_url}
				/>
				<div
					className="Title"
				>
					{title}
				</div>
				<div
					className="Materials"
				>
					{materials}
				</div>
			</div>
		);
	}

  pageClass () {
    return 'TrashPage';
  }
}
