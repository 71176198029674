const QUOTES = [
  "Do alligators alligate?",
  "I'm a furniture.",
  "Okay, but if I win, you'll have to teach me how to play this game.",
  "My not-dead grandma sent it from Tokyo.",
  "That's me because I swim with my flotuses on.",
  "I cheated wrong. I copied the Lisa name and used the Ralph answers.",
  "Mr. Luther King had a dream. Dreams are where Elmo and Toy Story had a party, and I went there. Yay, my turn is over.",
  "Clouds are God's sneezes.",
  "Um, Miss Hoover? There's a dog in the vent.... He was going to the bathroom.",
  "Sir, I got carsick in your office.",
  "I'm Idaho!",
  "What's a battle?",
  "I Choo-Choo-Choose you.",
  "My parents won't let me use scissors.",
  "Miss Hoover. I don't have a red crayon.... I ate it.",
  "Miss Hoover, I glued my head to my shoulder.",
  "Uh... so... do you like..... stuff?",
  "The doctor said I wouldnt have so many nosebleeds if I kept my finger outta there.",
  "He's still funny, but not ha-ha funny.",
  "Salmon gutter?",
  "And when the doctor said I didn't have worms anymore, that was the happiest day of my life!",
  "I'm going to eat chocolate till I barf!",
  "Dear Miss Hoover. You have Lyme disease. We miss you. Kevin's biting me, come back soon. Here is a drawing of a spyrokeet. Love Ralph.",
  "Me fail English? That's unpossible.",
  "Hi, Super Nintendo Chalmers!",
  "Mrs. Krabappel and Principal Skinner were in the closet making babies and I saw one of the babies and then the baby looked at me.",
  "Eww, Daddy, this tastes like Gramma!",
  "I bent my wookie.",
  "Daddy, I'm scared. Too scared to even wet my pants.",
  "I heard your dad went into a restaurant and ate everything in the restaurant and they had to close the restaurant.",
  "I found a moonrock in my nose!",
  "That's where I saw the Leprchaun. He tells me to burn things!",
  "Go fish.",
  "This is my swing set. This is my sandbox. I'm not allowed to go in the deep end.",
  "Wanna play stuffed animal parade?",
  "He's gonna smell like hotdogs!",
  "Do I live here?",
  "Are you my mommy?",
  "I can put my finger in my nose.",
  "My cat's breath smells like cat food.",
  "Hello! I'm Dr. Stupid. I'm gonna take out your liver bones!",
  "One! One! One! One! Go!",
  "Fireworks make my ears yell!",
  "My daddy's gonna put you in jail.",
  "I want a tricycle, and a dog who won't chew my Hot Wheels, and a brighter future for America. I'm Ralph Wiggum, and I've been a good boy.",
  "I look like cable tv!",
  "Can you open my milk, Mommy?",
  "That is so 1991.",
  "I'm a unitard!",
  "And I want a bike, and a monkey, and a friend for the monkey...",
  "At my house, we call them uh-ohs.",
  "I'm special!",
  "When I grow up, I'm going to Bovine University!"
];

export default class RalphWiggum {
  constructor () {
    this.index = this.constructor.randomIndex();
  }

  get quote () {
    return QUOTES[this.index];
  }

  next () {
    this.index = this.constructor.randomIndex();
    return this.quote;
  }

  static randomIndex () {
    return parseInt(Math.random() * QUOTES.length);
  }

  static random () {
    const index = this.randomIndex();
    return QUOTES[index];
  }
}
