import React from 'react';

import {
  Page,
} from '../Core/';

import toZ16 from './toZ16';
import Z16SquareCanvas from './Z16SquareCanvas';
import Z16Square from './Z16Square';
import Z16OperatorsLegend from './Z16OperatorsLegend';
import randomOp from './randomOp';
import './Square.scss';

function randomScale () {
  const scales = [2, 4, 8, 16, 32, 64, 128];
  const index = Math.floor(Math.random() * scales.length);
  return scales[index];
}

class SquareCanvasTiled extends React.Component {
  constructor (props) {
    super(props);
    const {pattern} = props;
    this.state = {
      pattern,
      width: null,
      height: null,
      scale: null
    };
  }

  componentDidMount () {
    const {
      innerWidth: width,
      innerHeight: height
    } = window;

    const scale = randomScale();

    let {pattern, op} = this.props;
    if (pattern) {
      // TODO: support grouping?
      pattern = pattern.split(',').map(toZ16);
    } else {
      pattern = Z16Square.generatePattern({width, height, scale});
      op = randomOp();
      setInterval(this.randomize, 2048);
      setInterval(this.operate, 64);
    }

    this.setState({width, height, pattern, scale, op});
  }

  randomize = ()=> {
    const {width, height} = this.state;
    const scale = randomScale();
    const pattern = Z16Square.generatePattern({width, height, scale});
    const op = randomOp();
    this.setState({scale, pattern, op});
  }

  operate = ()=> {
    const {pattern, op} = this.state;
    const new_pattern = pattern.map(op);
    this.setState({
      pattern: new_pattern
    });
  }

  render () {
    const {width, height, pattern, scale} = this.state;
    if (!(width && height)) {
      return '';
    }

    const square = Z16Square
      .fromPattern(pattern)
      .fit({width, height, scale});

    //console.log(JSON.stringify(square.print()));

    const size = scale * square.size;

    return (
      <div
        className="SquareCanvasTiled"
        style={{
          marginTop: (height - size) / 2,
          width: size,
          height: size
        }}
      >
        <Z16SquareCanvas
          square={square}
          scale={scale}
        />
      </div>
    );
  }
}

class SquarePage extends Page {
  title () {
    return 'Square';
  }

  pageClass () {
    return 'SquarePage';
  }

  renderBody() {
    const { pattern } = this.props.match.params;

    if (pattern === 'ops') {
      return (
        <Z16OperatorsLegend />
      );
    } else {
      return (
        <SquareCanvasTiled pattern={pattern} />
      );
    }
  }
}

export default SquarePage;
