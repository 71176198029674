import _ from 'lodash';

import Z16 from './Z16';
import toZ16 from './toZ16';
import randomZ16 from './randomZ16';
import squarePoint from './squarePoint';
import z16ToBitsFromTopLeft from './z16ToBitsFromTopLeft';
import * as Operators from './Operators';

export default class Z16Square {
  constructor ({z16, squares, size, dim}) {
    if (z16 !== undefined) {
      size = 2;
      dim = 0;
    }
    this.squares = squares;
    this.z16 = z16;
    this.size = size;
    this.dim = dim;
  }

  static fromOp (op) {
    return new this({
      z16: op(Z16)
    });
  }

  static fromZ16 (z) {
    return new this({
      z16: toZ16(z)
    });
  }

  static fromPattern (pattern) {
    const [first, ...rest] = pattern;
    let square = this.fromZ16(first);
    for (let i = 0; i < rest.length; i++) {
      const z = rest[i];
      square = square.nest(z);
    }
    return square;
  }

  static generatePattern ({width, height, scale}) {
    const min_dim = Math.min(width, height);
    const tile_scale = min_dim / scale;
    const max_depth = Math.floor(Math.log2(tile_scale));
    const depth = Math.ceil(Math.random() * max_depth);
    return _.range(depth).map(randomZ16);
  }

  fit ({width, height, scale}) {
    const {size} = this;
    const scaled_size = scale * size;
    const min_dim = Math.min(width, height);
    const new_size = Math.floor(min_dim / scaled_size) * size;
    const times = new_size / size;
    return this.tile(times);
  }

  render ({context, scale, x, y}) {
    const {z16, squares, size, dim} = this;

    if (z16 !== undefined) {
      // base case of z16 int
      const bits = z16ToBitsFromTopLeft(z16);
      for (let i = 0; i < bits.length; i++) {
        const bit = bits[i];
        const [bx, by] = squarePoint({i, size});
        context.fillStyle = (bit === 1) ? '#000000' : '#ffffff';
        const args = [x + bx, y + by, 1, 1].map((i)=> i * scale);
        context.fillRect(...args);
        // if (outline) {
        //   context.strokeStyle = '#777777';
        //   const scale_2x = scale * 2;
        //   context.strokeRect(x, y, scale_2x, scale_2x);
        // }
      }
    } else {
      // otherwise recurse on the list of squares
      for (let i = 0; i < squares.length; i++) {
        const [sx, sy] = squarePoint({i, size: dim});
        const square = squares[i];
        const {size} = square;

        square.render({
          context,
          scale,
          x: x + (sx * size),
          y: y + (sy * size)
        });
      }
    }
  }

  op (op) {
    const {z16, squares} = this;
    if (z16 !== undefined) {
      this.z16 = op(z16);
    } else {
      this.squares = squares.map((square)=> square.op(op));
    }
    return this;
  }

  clone () {
    const {z16, size, dim} = this;
    let {squares} = this;
    if (z16 === undefined) {
      squares = squares.map((square)=> square.clone());
    }
    return new this.constructor({z16, squares, size, dim});
  }

  zero () {
    return new this.constructor({
      z16: 0
    });
  }

  print () {
    const {z16, squares} = this;
    if (z16 !== undefined) {
      return z16;
    } else {
      return squares.map((s)=> s.print());
    }
  }

  nest (z16) {
    const dim = 2;
    const size = this.size * dim;

    const bits = z16ToBitsFromTopLeft(z16);

    const squares = bits.map((bit)=> {
      if (bit === 1) {
        return this.clone();
      } else {
        return this.zero();
      }
    });

    return new this.constructor({size, squares, dim});
  }

  tile (dim) {
    const num_clones = dim * dim;
    const size = this.size * dim;
    const squares = [];
    for (let i = 0; i < num_clones; i++) {
      squares.push(this.clone());
    }
    return new this.constructor({squares, size, dim});
  }
}
