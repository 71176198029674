import React from 'react';

import {
  Page,
  VideoComponent
} from '../Core/';
import Videos from './Videos';
import './Videos.scss';

export default class VideoPage extends Page {
  title () {
		return this.props.title;
  }

  meta () {
		let {
			title,
			description
		} = this.props;

    return {
      description: (title || description)
    };
  }

  pageClass () {
    return 'VideoPage';
  }

	getVideo () {
		let {match: {params: {title}}} = this.props;
		return Videos.find((video)=> {
			return (video.url_title === title);
		});
	}

	renderBody () {
		let video = this.getVideo();
		return (
			<VideoComponent
				{...video}
				size="FULLSCREEN"
			/>
		);
	}
}
