import React, {useState} from 'react';
import styled from 'styled-components';

import {range} from './Core';

const WoodPageStyled = styled.div`
  .Image {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
  }
  .Controls {
    position: fixed;
    z-index: 95;
    top: 20px;
    right: 20px;

    div {
      cursor: pointer;
      font-size: 100px;
      color: white !important;
      text-decoration: none;
      font-weight: bold;
    }
  }
`;

export default function WoodPage () {
  const [index, setIndex] = useState(1);
  const src = `/img/wood/wood${index}.png`;

  const background = `url(${src}) no-repeat center center fixed`;

  const $controls = range(1, 4).map((i)=> {
    return (
      <div
        onClick={()=> setIndex(i)}
      >
        {i}
      </div>
    );
  });

  return (
    <WoodPageStyled>
      <img className="Image" src={src}/>
      <div className="Controls">
        {$controls}
      </div>
    </WoodPageStyled>
  );
}
