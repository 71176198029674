import React from 'react';
import styled from 'styled-components';

const GiftastropheStyled = styled.a`
  display: block;
  margin-left: 10px;
  margin-top: 10px;
  text-decoration: none;
`;

export default function GiftastrophePage () {
  return (
    <GiftastropheStyled href="/img/gifs/giftastrophe.gif">
      this is a link to a 35.8 MB gif and it might slow down yr browser so no warranties if your unsaved google doc with all your favorite names for the cat you're going to adopt from the spca crashes and you have to retype it all over again which sucks because you'll probably get most of the good names again but there'll be a couple you forgot, and undoubtedly those will be the best ones. so i'm sorry in advance if that happens to you. warmest regards, maury ps. if for some reason you want to link to this then please don't, just download it. it won't hurt my feelings. even warmer regards, maury
    </GiftastropheStyled>
  );
}
